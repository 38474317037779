import React from "react";
import './ContenidoSoporte.css';

export const ContenidoSoporte = () => {
    return (
        <div className="contenido-soporte">
            <h2>📞 Contacto y Soporte</h2>
            <p className="descripcion">
                En esta sección podrás acceder a los correos de contacto de <strong>Grupo Derecho y Progreso, S.C.</strong> para solucionar cualquier duda, inconveniente o compartir sugerencias que puedas tener.
            </p>

            <div className="correos">
                <p>📩 <strong>Correo General:</strong> <a href="mailto:grupoderechoyprogresosc@gmail.com">grupoderechoyprogresosc@gmail.com</a></p>
                <p>📩 <strong>Atención:</strong> <a href="mailto:atenciongrupoderechoyprogreso@gmail.com">atenciongrupoderechoyprogreso@gmail.com</a></p>
            </div>

            <h3>🛠️ Herramientas Colaborativas</h3>
            <ul className="herramientas">
                <li>
                    <strong>📢 Foro de discusión:</strong> Es público para todos los usuarios registrados en la materia correspondiente. Aquí podrás comunicarte con el instructor.
                </li>
                <li>
                    <strong>💬 Mensaje interno:</strong> Puedes comunicarte con el área de soporte de GDP mediante un chat en tiempo real, ubicado en la parte inferior derecha de tu pantalla.
                </li>
            </ul>
        </div>
    );
}
