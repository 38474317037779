import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './RecoverPassword.css';
import {resetPassword} from './constants';
import logoBackground from '../Assets/logo.jpeg';
import background_img from '../Assets/background.jpg';
import emailIcon from '../Assets/mensajes.png';


function ResetPassword() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token"); // Obtener el token desde la URL

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [error, setError] = useState('');

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setError("Las contraseñas no coinciden.");
            return;
        }

        try {
            const response = await axios.post(resetPassword, { token, new_password: newPassword });
            setMensaje(response.data.message);
            setError('');
            setTimeout(() => navigate('/'), 3000); // Redirigir a login en 3 segundos
        } catch (err) {
            setError(err.response?.data?.error || 'Error al actualizar la contraseña');
            setMensaje('');
        }
    };

    return (
        <div className="fullpage">
            <div className='container'>
                <div className="background">
                    <img src={background_img} alt="" />
                </div>
                <div className="header">
                    <div className="logo">
                        <img src={logoBackground} alt="Logo" />
                    </div>
                </div>
                <div className="login-text">Restablecer Contraseña</div>
                <div className="inputs">
                    <div className="input">
                        <input 
                            type="password" 
                            placeholder="Nueva Contraseña" 
                            value={newPassword} 
                            onChange={(e) => setNewPassword(e.target.value)} 
                        />
                    </div>
                    <div className="input">
                        <input 
                            type="password" 
                            placeholder="Confirmar Contraseña" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                        />
                    </div>
                </div>
                {mensaje && <div className="success-message">{mensaje}</div>}
                {error && <div className="error-message">{error}</div>}
                <div className="submit-container">
                    <button onClick={handleResetPassword} disabled={!newPassword.trim() || !confirmPassword.trim()}>
                        Actualizar Contraseña
                    </button>
                </div>
                <div className="back-to-login">
                    <span onClick={() => navigate('/')} className="link">Volver al inicio de sesión</span>
                </div>
            </div>
        </div>
    );
}


export default ResetPassword;
