import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateClass.css';
import { routeCreateEvaluation, routeGetEvaluations, getClases } from "./constants";
import 'react-dropdown/style.css';

export const CreateEvaluation = () => {
    const [matriculaClase, setMatriculaClase] = useState('');
    const [idClase, setIdClase] = useState('');
    const [evaluacionDiagnostica, setEvaluacionDiagnostica] = useState('');
    const [evaluacionFinal, setEvaluacionFinal] = useState('');
    const [satisfaccion, setSatisfaccion] = useState('');
    const [error, setError] = useState('');
    const [optionsMatricula, setOptionsMatricula] = useState([]);
    const [matriculas, setMatriculas] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        callMatriculas();
    }, []);

    const callMatriculas = async () => {
        try {
            const response = await axios.get(getClases);
            const nuevasOpciones = response.data.map(clase => `${clase.matricula_clase} - ${clase.nombre_clase}`);
            setOptionsMatricula(nuevasOpciones);
            setMatriculas(response.data);
        } catch (error) {
            console.error('Error al obtener clases', error);
        }
    };

    const fetchEvaluacionData = async (idClase) => {
        try {
            const response = await axios.get(routeGetEvaluations, {
                params: { id_evaluaciones: idClase }
            });
            if (response.data) {
                setEvaluacionDiagnostica(response.data.evaluacion_diagnostica || '');
                setEvaluacionFinal(response.data.evaluacion_final || '');
                setSatisfaccion(response.data.satisfaccion || '');
            }
        } catch (error) {
            console.error('Error al obtener la evaluación', error);
        }
    };

    const handleSelection = (event) => {
        setMatriculaClase(event.target.value);
        const claseSeleccionada = matriculas.find(m => `${m.matricula_clase} - ${m.nombre_clase}` === event.target.value);
        if (claseSeleccionada) {
            setIdClase(claseSeleccionada.id_clase);
            fetchEvaluacionData(claseSeleccionada.id_clase); // Obtener datos al cambiar
        }
    };

    const handleSubmitEvaluation = async (e) => {
        e.preventDefault();
        setError(''); // 🔹 Limpiar errores previos
    
        try {
            const response = await axios.put(routeCreateEvaluation, {
                id_evaluaciones: idClase,
                evaluacion_diagnostica: evaluacionDiagnostica,
                evaluacion_final: evaluacionFinal,
                satisfaccion: satisfaccion
            });
    
            alert("Evaluación actualizada con éxito");
    
        } catch (error) {
            console.error("❌ Error al actualizar la evaluación:", error);
    
            if (error.response) {
                // 🔹 Si el servidor devuelve un error con un mensaje
                console.error("📌 Error del servidor:", error.response.data);
                setError(error.response.data.mensaje || "Ocurrió un error desconocido en el servidor.");
            } else if (error.request) {
                // 🔹 Si la petición no obtuvo respuesta del servidor
                console.error("📌 No se recibió respuesta del servidor.");
                setError("No se pudo conectar con el servidor.");
            } else {
                // 🔹 Errores inesperados
                console.error("📌 Error inesperado:", error.message);
                setError("Ocurrió un error inesperado. Inténtalo de nuevo.");
            }
        }
    };
    
    return (
        <div className="full-page">
            <div className='container1'>
                <form onSubmit={handleSubmitEvaluation}>
                    <div className="title"><h1>Crear Evaluación</h1></div>

                    <div className='class-name'>
                        <label>Matricula de clase</label>
                        <input
                            type="text"
                            placeholder="Escribe para buscar..."
                            value={matriculaClase}
                            onChange={handleSelection}
                            list="clases-list"
                        />
                        <datalist id="clases-list">
                            {optionsMatricula.map((option, index) => (
                                <option key={index} value={option} />
                            ))}
                        </datalist>
                    </div>

                    <div className='class-description'>
                        <label>Evaluación Diagnóstica</label>
                        <div><input type="text" value={evaluacionDiagnostica} onChange={(e) => setEvaluacionDiagnostica(e.target.value)} /></div>
                    </div>

                    <div className='teacher-id'>
                        <label>Evaluación Final</label>
                        <div><input type="text" value={evaluacionFinal} onChange={(e) => setEvaluacionFinal(e.target.value)} /></div>
                    </div>

                    {/* ✅ Encuesta de Satisfacción con el mismo estilo que los otros campos */}
                    <div className='teacher-id'>
                        <label>Encuesta de Satisfacción</label>
                        <div><input type="text" value={satisfaccion} onChange={(e) => setSatisfaccion(e.target.value)} /></div>
                    </div>

                    <div className='submit'>
                        <button type="submit">Crear Evaluaciones</button>
                        {error && <div>{error}</div>}
                    </div>
                </form>
                <div className='return-home'>
                    <span onClick={() => navigate("/admin-home")}>Regresar Home</span>
                </div>
            </div>
        </div>
    );
};
