import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useData } from './DataContext'; // Importa el contexto

import username_icon from '../Assets/username.png';
import password_icon from '../Assets/password.png';
import background_img from '../Assets/background.jpg';
import passwordEyeOpen_icon from '../Assets/showPassword.png';
import passwordEyeClosed_icon from '../Assets/noPassword.png';
import logoBackground from '../Assets/logo.jpeg';
import { apiLogin } from "./constants";

function Login() {
    const navigate = useNavigate();
    const { setData, setUserId } = useData();
    const [email, setEmail] = useState('');
    const [contraseña, setPassword] = useState('');
    const [errorM, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(hotmail|gmail|yahoo|outlook|example|derechoyprogreso)\.com$/;

    const isLoginDisabled = email.trim() === '' || contraseña.trim() === '';
    const isShowPasswordDisabled = contraseña.trim() === '';

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(apiLogin, { email, contraseña });
            const userData = response.data;
            setData(userData);
            setUserId(userData.id);
            
            switch (userData.rol.toLowerCase()) {
                case 'maestro': navigate("/teacher-home"); break;
                case 'alumno': navigate("/pagina-inicio"); break;
                case 'admin': navigate("/admin-home"); break;
                case 'monitor': navigate("/monitor-home"); break;
                default: break;
            }
        } catch (errorM) {
            setError('Correo electrónico o contraseña incorrecto/s.');
            setPassword('');
        }
    };

    return (
        <div className="fullpage">
            <div className='container'>
                <div className="background">
                    <img src={background_img} alt="" />
                </div>
                <div className="header">
                    <div className="logo">
                        <img src={logoBackground} alt="" />
                    </div>
                </div>
                <div className="login-text">Iniciar sesión con su correo institucional.</div>
                <div className="inputs">
                    <div className="input" id="emailInput">
                        <img src={username_icon} alt="" />
                        <input type="email" placeholder='Correo Electrónico' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="input">
                        <img src={password_icon} alt="" />
                        <input type={passwordVisible ? "text" : "password"} placeholder='Contraseña' value={contraseña} onChange={(e) => setPassword(e.target.value)} />
                        <div className="show-password">
                            <button onClick={() => setPasswordVisible(!passwordVisible)} disabled={isShowPasswordDisabled}>
                                <img src={passwordVisible ? passwordEyeOpen_icon : passwordEyeClosed_icon} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
                
                {/* Enlace para recuperar contraseña */}
                {/*<div className="forgot-password">
                    ¿Olvidaste tu contraseña? <span onClick={() => navigate('/recover-password')} className="link">Recupérala aquí</span>
                </div>*/}

                <div className="error-message"> {errorM && <div>{errorM}</div>} </div>
                <div className="submit-container">
                    <button onClick={handleSubmit} disabled={isLoginDisabled}>Iniciar Sesión</button>
                </div>
            </div>
        </div>
    );
}

export default Login;
