import React from 'react';
import BarraInferior from '../Others/BarraInferior';
import BotonBarraInferior from '../Others/BotonBarraInferior';

const BarraInferiorPersonalizadaTeacher = () => {
    return (
        <BarraInferior contenido={
            <>
        <BotonBarraInferior imagenSrc={require("../Assets/tablon.png")} texto={"Foro de Discusión"} redireccion={"t-pagina-tablon"} />
        <BotonBarraInferior imagenSrc={require("../Assets/contenido.png")} texto={"Contenido"} redireccion={"t-pagina-content"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Ejercicios"} redireccion={"t-pagina-tareas"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Diagnostica"} redireccion={"t-pagina-evaluacion"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Final"} redireccion={"t-pagina-evaluacionfi"} />
    </>
        } />
    );
}

export default BarraInferiorPersonalizadaTeacher;
