import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ContenedorAnuncios.css';
import { obtenerPostsPorClase, crearPost, crearRepost, obtenerReposts } from '../Pages/constants';
import { useData } from '../Pages/DataContext';

export const ContenedorAnuncios = () => {
    const { dataClase } = useData();  // 📌 Contiene `idClase`
    const { data } = useData();
    const idClase = dataClase?.id_clase || null;

    const [posts, setPosts] = useState([]);
    const [reposts, setReposts] = useState({});
    const [nuevoPost, setNuevoPost] = useState("");
    const [repostContent, setRepostContent] = useState({});  // 📌 Almacena los mensajes de repost por post
    const [error, setError] = useState('');
    const [mensaje, setMensaje] = useState('');

    useEffect(() => {
        if (idClase) {
            fetchPosts(idClase);
        }
    }, [idClase]);

    const fetchPosts = async (idClase) => {
        try {
            const response = await axios.get(obtenerPostsPorClase(idClase));
            setPosts(response.data);
            
            // 🔄 Obtener reposts para cada post
            const repostsData = {};
            for (let post of response.data) {
                const res = await axios.get(obtenerReposts(post.id_post));
                repostsData[post.id_post] = res.data;
            }
            setReposts(repostsData);
        } catch (error) {
            console.error("❌ Error al obtener posts:", error);
            setError("No se pudieron cargar los posts.");
        }
    };

    const handleCrearPost = async () => {
        if (!nuevoPost.trim()) {
            setError("El contenido del post no puede estar vacío.");
            return;
        }

        try {
            const response = await axios.post(crearPost, {
                id_clase: idClase,
                user_id: data.id,  // 📌 Usuario autenticado
                content: nuevoPost
            });

            if (response.data.success) {
                setMensaje("✅ Post creado exitosamente.");
                setNuevoPost("");
                fetchPosts(idClase);  // 🔄 Refrescar los posts después de crear uno nuevo
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error("❌ Error al crear post:", error);
            setError("No se pudo crear el post.");
        }
    };

    const handleRepost = async (idPost) => {
        const content = repostContent[idPost]?.trim();
        if (!content) {
            setError("El contenido del repost no puede estar vacío.");
            return;
        }

        try {
            const response = await axios.post(crearRepost(idPost), {
                user_id: data.id,  // 📌 Usuario autenticado
                content: content
            });

            if (response.data.success) {
                setMensaje("✅ Post reposteado exitosamente.");
                setRepostContent((prev) => ({ ...prev, [idPost]: "" })); // 🔄 Limpiar input
                fetchPosts(idClase);  // 🔄 Refrescar los posts después de repostear
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error("❌ Error al repostear:", error);
            setError("No se pudo repostear el post.");
        }
    };

    return (
        <div className='contenedor-anuncios'>
            <h2>📢 Últimos Posts</h2>
            
            {/* ✅ Input para Crear Post */}
            <div className="crear-post">
                <textarea 
                    placeholder="Escribe un nuevo post..." 
                    value={nuevoPost} 
                    onChange={(e) => setNuevoPost(e.target.value)}
                />
                <button onClick={handleCrearPost}>Publicar</button>
            </div>

            {error && <p className="error">{error}</p>}
            {mensaje && <p className="mensaje">{mensaje}</p>}

            {/* ✅ Listado de Posts */}
            {posts.length === 0 ? (
                <p>No hay posts disponibles.</p>
            ) : (
                posts.map((post, index) => (
                    <div key={index} className="post">
                        <h3>{post.content}</h3>
                        <p><strong>Publicado por:</strong> {post.nombre} {post.apellido}</p>
                        <span>{new Date(post.created_at).toLocaleString()}</span>

                        {/* ✅ Input para escribir el mensaje de repost */}
                        <div className="repost-section">
                            <textarea
                                placeholder="Escribe tu mensaje de repost..."
                                value={repostContent[post.id_post] || ""}
                                onChange={(e) => setRepostContent((prev) => ({
                                    ...prev,
                                    [post.id_post]: e.target.value
                                }))}
                            />
                            <button onClick={() => handleRepost(post.id_post)}>🔁 Repostear</button>
                        </div>

                        {/* ✅ Mostrar Reposteos */}
                        {reposts[post.id_post] && reposts[post.id_post].length > 0 && (
                            <div className="repost-list">
                                <h4>🔄 Reposteos:</h4>
                                {reposts[post.id_post].map((repost, idx) => (
                                    <div key={idx} className="repost">
                                        <p>{repost.content}</p>
                                        <span><strong>Por:</strong> {repost.nombre} {repost.apellido}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );
};
