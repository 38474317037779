import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { crearAsignacion, obtenerAsignacionesPorClase } from "./constants";
import { useData } from './DataContext';
import './AsignarTareas.css'; // 📌 Importamos los estilos

const AsignarTareas = () => {
    const { dataClase } = useData();
    const [nombre, setNombre] = useState('');
    const [instrucciones, setInstrucciones] = useState('');
    const [fechaLimite, setFechaLimite] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [error, setError] = useState('');
    const [tareas, setTareas] = useState([]);

    // 📌 Cargar las tareas al montar el componente y cuando `dataClase` cambie
    useEffect(() => {
        if (dataClase?.matricula_clase) {
            obtenerTareasAsignadas();
        } else {
            console.warn("⚠️ dataClase no está disponible todavía.");
        }
    }, [dataClase]);  

    const obtenerTareasAsignadas = async () => {
        if (!dataClase?.matricula_clase) {
            console.error("❌ No se encontró la matrícula de la clase.");
            setError("❌ No se pudo obtener la lista de tareas. Verifica la clase seleccionada.");
            return;
        }
    
        const url = obtenerAsignacionesPorClase(dataClase.matricula_clase);
        console.log("📌 URL de la API para obtener tareas:", url);
    
        try {
            console.log("✅ Solicitando tareas desde la API...");
            const response = await axios.get(url);
            console.log("📌 Respuesta completa de la API:", response.data);
    
            // 📌 Asegurar que `response.data.asignaciones` sea un array
            if (Array.isArray(response.data)) {
                console.log("✅ Datos correctos, guardando en el estado...");
                setTareas(response.data); // 💡 Ahora sí estamos guardando el array correcto
            } else {
                console.error("❌ La API no devolvió un array. Datos recibidos:", response.data);
                setTareas([]); // Evita errores asignando un array vacío
            }
        } catch (error) {
            console.error("❌ Error al obtener las tareas asignadas:", error);
            setError("❌ Error al cargar las tareas asignadas.");
            setTareas([]); // 📌 Evita que el estado se quede en undefined
        }
    };
    

    // 📌 Función para enviar una nueva tarea
    const handleSubmit = async (event) => { 
        event.preventDefault();

        if (!nombre || !instrucciones || !fechaLimite) {
            setError('❌ Todos los campos son obligatorios.');
            setMensaje('');
            return;
        }

        try {
            await axios.post(crearAsignacion, {
                nombre,
                instrucciones,
                matricula_clase: dataClase.matricula_clase,
                fecha_limite: fechaLimite,
                calificacion_maxima: 10,
                estado: "abierta",
                tipo_asignacion: "tarea",
                archivo_referencia: null
            });

            setMensaje('✅ Tarea asignada con éxito.');
            setError('');
            setNombre('');
            setInstrucciones('');
            setFechaLimite('');

            obtenerTareasAsignadas(); // 📌 Recargar lista de tareas
        } catch (error) {
            setError('❌ Error al asignar la tarea.');
            setMensaje('');
            console.error('Error:', error);
        }
    };

    return (
        <div className="contenedor-asignaciones">
            <h2>📚 Asignar Nueva Tarea</h2>

            <form onSubmit={handleSubmit} className="form-asignacion">
                <fieldset>
                    <legend>📝 Detalles de la Tarea</legend>

                    <div className="campo">
                        <label>📌 Nombre de la Tarea:</label>
                        <input 
                            type="text" 
                            placeholder="Ejemplo: Ensayo sobre Derechos Humanos" 
                            value={nombre} 
                            onChange={(e) => setNombre(e.target.value)} 
                        />
                    </div>

                    <div className="campo">
                        <label>📝 Instrucciones:</label>
                        <textarea 
                            placeholder="Describa las instrucciones de la tarea aquí..." 
                            value={instrucciones} 
                            onChange={(e) => setInstrucciones(e.target.value)}
                        ></textarea>
                    </div>

                    <div className="campo">
                        <label>📅 Fecha Límite:</label>
                        <input 
                            type="datetime-local" 
                            value={fechaLimite} 
                            onChange={(e) => setFechaLimite(e.target.value)} 
                        />
                    </div>
                </fieldset>

                <button type="submit" className="boton-asignar">✅ Asignar Tarea</button>

                {mensaje && <p className="mensaje mensaje-exito">{mensaje}</p>}
                {error && <p className="mensaje mensaje-error">{error}</p>}
            </form>

            {/* 📌 Botón para recargar manualmente las tareas */}
            <button onClick={obtenerTareasAsignadas} className="boton-recargar">
                🔄 Recargar Tareas Asignadas
            </button>

            {/* 📌 Tabla de tareas asignadas */}
            <h3>📌 Tareas Asignadas</h3>
            {tareas.length === 0 ? (
                <p className="mensaje">No hay tareas asignadas.</p>
            ) : (
                <table className="tabla-tareas">
                    <thead>
                        <tr>
                            <th>📌 Nombre</th>
                            <th>📝 Instrucciones</th>
                            <th>📅 Fecha Límite</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tareas.map((tarea, index) => (
                            <tr key={index}>
                                <td>{tarea.nombre}</td>
                                <td>{tarea.instrucciones}</td>
                                <td>{new Date(tarea.fecha_limite).toLocaleString()}</td> {/* 📌 Formato de fecha */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AsignarTareas;
