import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useData } from '../Pages/DataContext';

import './ContenidoNoticias.css';
import { listaNoticias, descargarImagenNoticia, eliminarNoticia } from '../Pages/constants';

const ContenidoNoticias = () => {
  const { data } = useData();
  const [noticias, setNoticias] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log(data);
    axios.get(listaNoticias)
      .then(response => {
        const { data, success } = response.data;
        if (success && Array.isArray(data)) {
          setNoticias(data);
        } else {
          setError('La respuesta no es la esperada o success es false');
        }
      })
      .catch(error => {
        console.error('Error al obtener noticias:', error);
        setError('Ocurrió un error al cargar las noticias');
      });
  }, []);

  // Función para eliminar una noticia
  const handleEliminarNoticia = async (noticiaId) => {
    if (!window.confirm("¿Estás seguro de que deseas eliminar esta noticia?")) return;

    try {
      const response = await axios.delete(eliminarNoticia, { data: { id: noticiaId } });

      if (response.data.success) {
        setNoticias(noticias.filter(noticia => noticia.id !== noticiaId)); // Eliminar noticia del estado
        alert("Noticia eliminada correctamente.");
      } else {
        alert("No se pudo eliminar la noticia.");
      }
    } catch (error) {
      console.error("Error al eliminar la noticia:", error);
      alert("Ocurrió un error al intentar eliminar la noticia.");
    }
  };

  return (
    <div className="contenido-noticias">
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {noticias.map(noticia => (
        <div key={noticia.id} className="noticia-item">
          <h4>{noticia.titulo}</h4>
          <p>{noticia.contenido}</p>
          
          {noticia.url && (
            <a href={noticia.url} target="_blank" rel="noopener noreferrer" className="noticia-url">
              {noticia.url}
            </a>
          )}

          {noticia.imagen && (
            <img
              src={`${descargarImagenNoticia}${noticia.imagen.replace('\\', '/')}`}
              alt={noticia.titulo}
            />
          )}

          {/* Mostrar botón de eliminar solo si el usuario es admin */}
          {data.rol && (data.rol.toLowerCase() === "admin") && (
            <button className="boton-eliminar" onClick={() => handleEliminarNoticia(noticia.id)}>
              🗑️ Eliminar
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default ContenidoNoticias;
