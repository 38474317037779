import React from "react";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { saveAs } from "file-saver";

const CertificadoPDF = ({ nombres, paterno, materno, capacitacion, fecha, horas, expedicion }) => {
    const generarPDF = async () => {
        try {
            console.log("🔄 Cargando plantilla PDF...");
            const pdfBaseResponse = await fetch("/certificado_qr_template.pdf");
            if (!pdfBaseResponse.ok) throw new Error("❌ Error al cargar la plantilla PDF");
            const pdfBaseBytes = await pdfBaseResponse.arrayBuffer();
            console.log("✅ Plantilla PDF cargada correctamente.");

            console.log("🔄 Cargando fuentes desde /fonts/...");
            const cambriaResponse = await fetch("/fonts/Cambria.ttf");
            const cambriaBoldResponse = await fetch("/fonts/Cambria-Bold.ttf");

            if (!cambriaResponse.ok || !cambriaBoldResponse.ok) {
                throw new Error("❌ No se encontraron las fuentes Cambria en /public/fonts/");
            }

            console.log("✅ Fuentes encontradas correctamente. Cargando...");
            const cambriaBytes = await cambriaResponse.arrayBuffer();
            const cambriaBoldBytes = await cambriaBoldResponse.arrayBuffer();

            // 2️⃣ Cargar el PDF en pdf-lib
            const pdfDoc = await PDFDocument.load(pdfBaseBytes);
            pdfDoc.registerFontkit(fontkit);

            // 3️⃣ Incrustar fuentes
            console.log("🔄 Incrustando fuentes en el PDF...");
            const fontRegular = await pdfDoc.embedFont(cambriaBytes);
            const fontBold = await pdfDoc.embedFont(cambriaBoldBytes);
            console.log("✅ Fuentes incrustadas correctamente.");

            const fontSizeNombre = 32;
            const fontSizeTexto = 18;
            const page = pdfDoc.getPages()[0];
            const pageWidth = page.getWidth();
            const pageHeight = page.getHeight();

            // 4️⃣ Subimos la posición del texto en Y (+50px más arriba)
            let yPosition = pageHeight / 2 + 80; 

            // 5️⃣ Escribir los nombres en tamaño grande
            const nombreCompleto = `${nombres} ${paterno} ${materno}`;
            const textWidthNombre = fontRegular.widthOfTextAtSize(nombreCompleto, fontSizeNombre);
            const xPositionNombre = (pageWidth - textWidthNombre) / 2;
            page.drawText(nombreCompleto, { x: xPositionNombre, y: yPosition, size: fontSizeNombre, font: fontRegular, color: rgb(0, 0, 0) });
            yPosition -= 50;

            // 6️⃣ Texto del certificado con mayor ancho (expandido a los lados)
            const textoCertificado = [
                "En reconocimiento por su participación en la acción de capacitación",
                `"${capacitacion}"`, // 🔹 En negritas
                `llevada a cabo de manera presencial a distancia los días ${fecha},`,
                `con una duración de ${horas} horas.`,
                `Se expide en Ciudad de México a ${expedicion}.`
            ];

            textoCertificado.forEach((linea, index) => {
                const isBold = index === 1; // Solo la capacitación en negritas
                const font = isBold ? fontBold : fontRegular;
                let textWidth = font.widthOfTextAtSize(linea, fontSizeTexto);
                
                // 🔹 Reducimos márgenes laterales
                let xPos = (pageWidth - textWidth) / 2 - 30; // Expandimos 30px más a cada lado

                // 🔹 Si el texto es muy largo, reducimos el tamaño
                if (textWidth > pageWidth - 80) { // Dejamos margen de 40px en cada lado
                    page.drawText(linea, { x: 40, y: yPosition, size: fontSizeTexto - 2, font, color: rgb(0, 0, 0) });
                } else {
                    page.drawText(linea, { x: xPos, y: yPosition, size: fontSizeTexto, font, color: rgb(0, 0, 0) });
                }
                yPosition -= 25;
            });

            console.log("✅ Texto ajustado correctamente dentro del PDF.");

            // 7️⃣ Guardar el nuevo PDF
            const pdfBytes = await pdfDoc.save();
            console.log("✅ PDF generado correctamente. Descargando...");

            // 8️⃣ Descargar el archivo
            const blob = new Blob([pdfBytes], { type: "application/pdf" });
            saveAs(blob, "Certificado.pdf");

        } catch (error) {
            console.error("❌ Error generando el PDF:", error);
            alert(`Error: ${error.message}`);
        }
    };

    return (
        <button onClick={generarPDF} className="btn-descargar">
            📥 Descargar Certificado
        </button>
    );
};

export default CertificadoPDF;
