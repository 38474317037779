import React, { useState, useEffect } from 'react';
import './ChatWidget.css';
import messageIcon from '../Assets/menssage.png'; 


const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  useEffect(() => {
    /*socket.on('message', (data) => {
      setMessages(prev => [...prev, data]);
    });
    return () => {
      socket.off('message');
    };*/
  }, []);

  const sendMessage = () => {
    /*if (input.trim()) {
      const data = {
        username: 'Usuario1', // Reemplaza con el usuario real
        message: input,
        room: 'chatroom'
      };
      socket.emit('message', data);
      setInput('');
    }*/
  };

  return (
    <div className="chat-widget-container">
      {isOpen ? (
        <div className="chat-widget open">
          <div className="chat-header" onClick={() => setIsOpen(false)}>
            Cerrar Chat
          </div>
          <div className="chat-body">
            <div className="messages">
              {messages.map((msg, i) => (
                <div key={i}>
                  <strong>{msg.username}:</strong> {msg.message}
                </div>
              ))}
            </div>
            <div className="chat-input">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Escribe tu mensaje..."
              />
              <button onClick={sendMessage}>Enviar</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="chat-button" onClick={() => setIsOpen(true)}>
         <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img 
            src={messageIcon}
            alt="Abrir chat" 
            style={{ width: '30px', height: '30px' }}
          />
          <p style={{ fontSize: "10px", marginTop: "5px", color: "#fff" }}>
            Mensaje Interno
          </p>
        </div>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;
