import React from 'react';
import BarraInferior from '../Others/BarraInferior';
import BotonBarraInferior from '../Others/BotonBarraInferior';

const BarraInferiorPersonalizadaStudent = () => {
    return (
        <BarraInferior contenido={
            <>
                <BotonBarraInferior imagenSrc={require("../Assets/tablon.png")} texto={"Foro de Discusión"} redireccion={"pagina-tablon"} />
                <BotonBarraInferior imagenSrc={require("../Assets/contenido.png")} texto={"Contenido"} redireccion={"pagina-content"} />
                <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Ejercicios"} redireccion={"pagina-tareas"} />
                <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Diagnostica"} redireccion={"pagina-evaluacion"} />
                <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Final"} redireccion={"pagina-evaluacionfi"} />
                <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Encuesta de satisfaccion"} redireccion={"pagina-encuesta-satisfaccion"} />
            </>
        } />
    );
}

export default BarraInferiorPersonalizadaStudent;
