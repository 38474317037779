import React, { useState, useEffect } from 'react';
import './ContenedorMaterias.css';
import BotonMateria from './BotonMateria';
import imagen_materia from '../Assets/materia.jpg';
import loader_img from '../Assets/spin.svg';
import axios from 'axios';
import { getClasesStudent } from '../Pages/constants';
import { useData } from '../Pages/DataContext';

const ContenedorMateriasAlumno = ({ redireccionB }) => {
  const [materias, setMaterias] = useState([]);
  const { setClaseData } = useData();
  const { data } = useData();
  const [showing, setShowing] = useState(true);

  // Paginación
  const [paginaActual, setPaginaActual] = useState(1);
  const materiasPorPagina = 9;

  useEffect(() => {
    setTimeout(() => {
      setShowing(false);
      fetchMaterias();
    }, 3000);
  }, []);

  const fetchMaterias = async () => {
    try {
      const response = await axios.get(`${getClasesStudent}?matricula_alumno=${data.matricula}`);
      console.log("!!Contenedor Materias Alumno " + response.data.length);
      console.log("Response Status " + response.status);

      if (response.data.length > 0) {
        response.data.forEach(element => {
          console.log("Nombre de Clase " + element.nombre_clase);
        });

        setMaterias(response.data);
      } else {
        console.log("Entrando al error Materias Alumno");
        alert("Error al obtener las materias, intentalo nuevamente");
      }
    } catch (error) {
      alert("ERR_CMA: Error al obtener las materias, intentalo nuevamente");
      console.error('Error al obtener las materias:', error);
    }
  };

  const editarClase = (val) => {
    setClaseData(val);
    console.log("Valores de clase: ", val);
  };

  // Calcular total de páginas
  const totalPaginas = Math.ceil(materias.length / materiasPorPagina);

  // Calcular las materias de la página actual
  const indiceUltimaMateria = paginaActual * materiasPorPagina;
  const indicePrimeraMateria = indiceUltimaMateria - materiasPorPagina;
  const materiasActuales = materias.slice(indicePrimeraMateria, indiceUltimaMateria);

  return (
    <div className="contenedor-materias">
      {showing ? (
        <img src={loader_img} className="center" alt="Cargando..." />
      ) : (
        <>
          {materiasActuales.map((materia, index) => (
            <BotonMateria
              key={index}
              imagen={imagen_materia}
              nombre={(index + 1 + indicePrimeraMateria) + " " + (materia.nombre_clase || "")}
              descripcion={materia.descripcion}
              salon={materia.matricula_clase}
              profesor={materia.matricula_maestro}
              redireccion={redireccionB}
              onEditarClase={() => editarClase(materia)}
            />
          ))}

          {/* Paginación centrada */}
          <div className="paginacion">
            <button
              onClick={() => setPaginaActual(paginaActual - 1)}
              disabled={paginaActual === 1}
            >
              Anterior
            </button>

            <span className="pagina-info">
              Página {paginaActual} de {totalPaginas}
            </span>

            <button
              onClick={() => setPaginaActual(paginaActual + 1)}
              disabled={paginaActual === totalPaginas}
            >
              Siguiente
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ContenedorMateriasAlumno;
