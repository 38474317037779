import React, { useState } from 'react'
import './PaginaInicio.css'
import './HomeworkPage.css'
import Cabecera from '../Others/Cabecera';
import PiePagina from '../Others/PiePagina';
import BotonBarraInferior from '../Others/BotonBarraInferior';
import BarraSuperior from '../Others/BarraSuperior';
import BarraInferior from '../Others/BarraInferior';
import axios from 'axios';
import { useData } from './DataContext'; // Importa el contexto
import GoogleFormF from '../Others/GoogleFormF'; 
import {routeGetFinalEvaluation} from "./constants";
import {routeGetDiagnosticEvaluation} from "./constants";


const barra_inferior = <BarraInferior contenido={
    <>
        <BotonBarraInferior imagenSrc={require("../Assets/tablon.png")} texto={"Foro de Discusión"} redireccion={"a-pagina-tablon"} />
        <BotonBarraInferior imagenSrc={require("../Assets/contenido.png")} texto={"Contenido"} redireccion={"a-pagina-content"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Ejercicios"} redireccion={"a-pagina-tareas"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Diagnostica"} redireccion={"a-pagina-evaluacion"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Final"} redireccion={"a-pagina-evaluacionfi"} />
        <BotonBarraInferior imagenSrc={require("../Assets/herramientas.png")} texto={"Eliminar Curso"} redireccion={"a-delete-course"} />
    </>
} />

const menu_materias = <>

</>
const menu_mensajes = <>

</>
const menu_alertas = <>

</>
const menu_actualizaciones = <>

</>


const AdminFormF = () => {
    const [formData, SetDataForm] =  useState([]);
    const { dataClase } = useData(); // Obtiene los datos del contexto

    const barra_superior = <BarraSuperior texto_cabecera={dataClase.nombre_clase} menu_materias={menu_materias} menu_mensajes={menu_mensajes} menu_alertas={menu_alertas} menu_actualizaciones={menu_actualizaciones} redireccion={"admin-home"} profile_redireccion={"a-profile-page"} />
    React.useEffect(()=>{
        callForm();
       },[]);

       const callForm = async () => {
        console.log("Esta madre se tiene que abrir");
        console.log("Data class", dataClase.id_clase);
        try {
            const response = await axios.get(routeGetDiagnosticEvaluation,{
                params: {
                    id_evaluaciones:dataClase.id_clase
                }
            });
            console.log(response.data);
            let temp = [];
            temp.push(response.data.evaluacion_diagnostica);
            console.log (temp);
            SetDataForm(temp);
            if (response.data == null) {
                alert("No hay ninguna evaluacion");
                //do something
            }
            
        } catch (error) {
            console.error('Error al obtener evaluacion', error);
            alert("Error al obtener la evaluacion");

        }
    }

    return (
        <div className='contenedor-pagina'>
            <Cabecera contenidosuperior={barra_superior} contenidoInferior={barra_inferior} />
            <div className="homework-container">
                <div className="h-title"><h1>Evaluacion Diagnostica</h1></div>
                <div className="h-holder">
                    <div className="h-content">
                        <p>¡Aquí está la encuesta diagnostica!</p>
                        <div>    
 
                    {formData.map((data) => (
                        <li key={data}>
                        <iframe 
                        src= {data}
                          width="640" 
                          height="800" 
                          frameborder="0" 
                          marginheight="0" 
                          marginwidth="0"
                          title="Google Form"
                          >
                          Cargando...
                        </iframe>
                            </li>
                    ))}
    </div>
                    </div>
                </div>
            </div>
            <PiePagina imagenSrc={require('../Assets/piepagina.jpg')} />
            <footer>Grupo Derecho & Progreso &copy; 2024</footer>
        </div>
    )
}

export default AdminFormF;