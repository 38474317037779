import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { calificarEntrega, obtenerAsignacionesPorClase, getGrupoMatricula, obtenerEntregasPorAsignacion, descargarUltimaEntregaPorAlumno } from "./constants";
import { useData } from './DataContext';
import './TeacherGradeHomework.css';

const TeacherGradeHomework = () => {
    const { dataClase } = useData();
    const [tareas, setTareas] = useState([]);
    const [calificaciones, setCalificaciones] = useState({});
    const [comentarios, setComentarios] = useState({});
    const [error, setError] = useState('');
    const [alumnos, setAlumnos] = useState([]);
    const [entregas, setEntregas] = useState({});

    useEffect(() => {
        if (!dataClase?.matricula_clase) return;

        let isMounted = true;

        const fetchData = async () => {
            if (isMounted) {
                await obtenerAlumnos();
                setTimeout(() => {
                    if (isMounted) obtenerTareasAsignadas();
                }, 500);
            }
        };

        fetchData();

        return () => { isMounted = false };
    }, [dataClase?.matricula_clase]);

    const obtenerTareasAsignadas = async () => {
        if (!dataClase?.matricula_clase) {
            setError("❌ No se pudo obtener la lista de tareas. Verifica la clase seleccionada.");
            return;
        }

        try {
            const url = obtenerAsignacionesPorClase(dataClase.matricula_clase);
            const response = await axios.get(url);
            if (Array.isArray(response.data)) {
                setTareas(response.data);
                const idsAsignaciones = response.data.map(asignacion => asignacion.id_asignacion);
                obtenerTodasLasEntregas(idsAsignaciones);
            } else {
                setTareas([]);
            }
        } catch (error) {
            setError("❌ Error al cargar las tareas asignadas.");
            setTareas([]);
        }
    };

    const obtenerTodasLasEntregas = async (idsAsignaciones) => {
        try {
            const nuevoEstado = {};
            for (let id of idsAsignaciones) {
                const response = await axios.get(obtenerEntregasPorAsignacion(id));
                if (response.data && Array.isArray(response.data.data)) {
                    response.data.data.forEach(entrega => {
                        const idAlumno = String(entrega.id_alumno);
                        if (!nuevoEstado[idAlumno]) {
                            nuevoEstado[idAlumno] = {};
                        }
                        nuevoEstado[idAlumno][String(id)] = entrega;
                    });
                }
            }
            setEntregas(nuevoEstado);
        } catch (error) {
            console.error("❌ Error al obtener todas las entregas:", error);
        }
    };

    const obtenerAlumnos = async () => {
        if (!dataClase?.matricula_clase) return;

        try {
            const response = await axios.post(getGrupoMatricula, { matricula_clase: dataClase.matricula_clase });
            if (response.data && Array.isArray(response.data.data)) {
                const alumnosData = response.data.data.map(alumno => ({
                    matricula: alumno.id_alumno_clase,
                    nombre: `${alumno.nombre_alumno} ${alumno.apellido_alumno}`
                }));
                setAlumnos(alumnosData);
            } else {
                setAlumnos([]);
            }
        } catch (error) {
            console.error("❌ Error al obtener los alumnos del grupo:", error);
            setAlumnos([]);
        }
    };

    const handleCalificar = async (id_entrega) => {
        const nota = calificaciones[id_entrega];
        const comentario = comentarios[id_entrega];

        if (!nota) {
            alert("⚠️ Debes ingresar una calificación antes de guardar.");
            return;
        }

        const confirmacion = window.confirm(`¿Estás seguro de calificar la entrega con una nota de ${nota} y el comentario: "${comentario}"?`);
        if (!confirmacion) return;

        try {
            const response = await axios.put(calificarEntrega, {
                id_entrega: id_entrega,
                calificacion: parseFloat(nota),
                comentarios: comentario || ""  // Enviar comentario vacío si no se ingresó uno
            });

            if (response.data.success) {
                alert("✅ Calificación y comentario guardados correctamente.");
            } else {
                alert("❌ Error al guardar: " + response.data.error);
            }
        } catch (error) {
            console.error("❌ Error en la solicitud:", error);
            alert("❌ Ocurrió un error al intentar guardar.");
        }
    };

    const descargarContenido = async (idAlumno, nombreArchivo = "Entrega") => {
        try {
            const response = await axios.get(`${descargarUltimaEntregaPorAlumno}?id_alumno=${idAlumno}`, {
                responseType: 'blob',
            });

            if (response.status === 200) {
                let nombreArchivoDescargado = "archivo_descargado.pdf";

                const contentDisposition = response.headers["content-disposition"];
                if (contentDisposition) {
                    const match = contentDisposition.match(/filename="?([^"]+)"?/);
                    if (match && match[1]) {
                        nombreArchivoDescargado = match[1];
                    }
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", nombreArchivoDescargado);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert("No se encontró ninguna entrega para este alumno.");
            }
        } catch (error) {
            console.error("Error al descargar el archivo:", error);
            alert("Error al descargar el archivo. Inténtalo nuevamente.");
        }
    };

    return (
        <div className="contenedor-calificaciones">
            <h2>📚 Listado y Calificación de Tareas</h2>
            {error && <p className="mensaje-error">{error}</p>}
            {tareas.map((tarea) => (
                <div key={tarea.id_asignacion} className="grupo-tarea">
                    <h3>{tarea.nombre}</h3>
                    <table className="tabla-calificaciones">
                        <thead>
                            <tr>
                                <th>👤 Estudiante</th>
                                <th>📥 Tarea Entregada</th>
                                <th>📊 Calificación</th>
                                <th>📝 Comentarios</th>
                                <th>✅ Guardar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alumnos.map((alumno) => {
                                const tareaEntregada = entregas[alumno.matricula]?.[tarea.id_asignacion];
                                return (
                                    <tr key={alumno.matricula}>
                                        <td>{alumno.nombre}</td>
                                        <td>
                                            {tareaEntregada ? (
                                                <button onClick={() => descargarContenido(alumno.matricula, `Entrega_${alumno.matricula}`)}>
                                                    📥 Descargar
                                                </button>
                                            ) : (
                                                "No entregado"
                                            )}
                                        </td>
                                        <td>
                                            {tareaEntregada && (
                                                <input
                                                    type="number"
                                                    step="0.1"
                                                    placeholder="Nota"
                                                    value={calificaciones[tareaEntregada.id_entrega] || tareaEntregada.calificacion || ""}
                                                    min="0"
                                                    max="10"
                                                    onChange={(e) =>
                                                        setCalificaciones({
                                                            ...calificaciones,
                                                            [tareaEntregada.id_entrega]: e.target.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {tareaEntregada && (
                                                <input
                                                    type="text"
                                                    placeholder="Añadir comentario"
                                                    value={comentarios[tareaEntregada.id_entrega] || tareaEntregada.comentarios || ""}
                                                    onChange={(e) =>
                                                        setComentarios({
                                                            ...comentarios,
                                                            [tareaEntregada.id_entrega]: e.target.value,
                                                        })
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {tareaEntregada && (
                                                <button onClick={() => handleCalificar(tareaEntregada.id_entrega)}>✅ Guardar</button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default TeacherGradeHomework;
