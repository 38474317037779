import React, { useState } from "react";
import axios from "axios";
import "./CreateNews.css"; // Importamos el CSS separado
import { subirNoticias } from "./constants";
import { useNavigate } from "react-router-dom";

const CreateNews = () => {
  const [titulo, setTitulo] = useState("");
  const [contenido, setContenido] = useState("");
  const [imagen, setImagen] = useState(null);
  const [fechaExpiracion, setFechaExpiracion] = useState("");
  const [url, setUrl] = useState(""); // 📌 Nuevo campo para la URL
  const navigate = useNavigate();

  const handleRegresarButton = () => {
    navigate("/admin-home");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!url.startsWith("http")) {
      alert("⚠️ Ingresa una URL válida (debe comenzar con http o https).");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("titulo", titulo);
      formData.append("contenido", contenido);
      if (imagen) {
        formData.append("imagen", imagen);
      }
      formData.append("fecha_expiracion", fechaExpiracion);
      formData.append("url", url); // 📌 Se agrega la URL

      const response = await axios.post(subirNoticias, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Noticia creada:", response.data);
      alert("✅ Noticia creada exitosamente");

      // Limpiar el formulario
      setTitulo("");
      setContenido("");
      setImagen(null);
      setFechaExpiracion("");
      setUrl(""); // 📌 Limpiar el campo de URL
    } catch (error) {
      console.error("❌ Error al crear la noticia:", error);
      alert("❌ Ocurrió un error al crear la noticia");
    }
  };

  return (
    <div className="create-news-container">
      <h2>📰 Crear Nueva Noticia</h2>
      <form onSubmit={handleSubmit} className="create-news-form">
        <div className="create-news-field">
          <label htmlFor="titulo">Título:</label>
          <input
            type="text"
            id="titulo"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            required
          />
        </div>

        <div className="create-news-field">
          <label htmlFor="contenido">Contenido:</label>
          <textarea
            id="contenido"
            value={contenido}
            onChange={(e) => setContenido(e.target.value)}
            required
          />
        </div>

        <div className="create-news-field">
          <label htmlFor="imagen">Imagen:</label>
          <input
            type="file"
            id="imagen"
            accept="image/*"
            onChange={(e) => setImagen(e.target.files[0])}
          />
        </div>

        <div className="create-news-field">
          <label htmlFor="fecha_expiracion">Fecha de Vencimiento:</label>
          <input
            type="date"
            id="fecha_expiracion"
            value={fechaExpiracion}
            onChange={(e) => setFechaExpiracion(e.target.value)}
          />
        </div>

        <div className="create-news-field">
          <label htmlFor="url">🔗 Enlace Externo:</label>
          <input
            type="url"
            id="url"
            placeholder="https://ejemplo.com"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="create-news-button">
          📤 Crear Noticia
        </button>
      </form>
      <div className="return-home">
        <span onClick={handleRegresarButton}>⬅️ Regresar a Inicio</span>
      </div>
    </div>
  );
};

export default CreateNews;
