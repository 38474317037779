import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { obtenerAsignacionesPorClase, getGrupoMatricula, calificacionesPorAsignacion } from './constants';
import { useData } from './DataContext';
import './TeacherGradeHomework.css';

const CalificacionTeacher = () => {
  const { dataClase } = useData();
  const [tareas, setTareas] = useState([]); // Asignaciones
  const [entregasPorAsignacion, setEntregasPorAsignacion] = useState({}); // { id_asignacion: [ entregas ] }
  const [alumnos, setAlumnos] = useState([]); // Lista de alumnos
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(''); // Estado para mostrar progreso
  const [error, setError] = useState('');

  // Función para procesar las peticiones de calificaciones de forma secuencial
  const fetchCalificacionesSecuencial = async (asignaciones) => {
    const results = [];
    for (let i = 0; i < asignaciones.length; i++) {
      const asignacion = asignaciones[i];
      try {
        const response = await axios.get(calificacionesPorAsignacion, { params: { id_asignacion: asignacion.id_asignacion } });
        results.push({
          id_asignacion: asignacion.id_asignacion,
          deliveries: Array.isArray(response.data.data) ? response.data.data : [],
        });
      } catch (err) {
        console.error(`Error al obtener calificaciones para asignación ${asignacion.id_asignacion}:`, err);
        results.push({ id_asignacion: asignacion.id_asignacion, deliveries: [] });
      }
      // Actualiza el progreso para indicar cuántas solicitudes se han completado
      setProgress(`Obteniendo calificaciones: ${i + 1} de ${asignaciones.length}`);
    }
    return results;
  };

  useEffect(() => {
    if (!dataClase?.matricula_clase) return;

    const fetchData = async () => {
      try {
        // 1. Obtener alumnos (grupo)
        const responseAlumnos = await axios.post(getGrupoMatricula, {
          matricula_clase: dataClase.matricula_clase,
        });
        if (responseAlumnos.data && Array.isArray(responseAlumnos.data.data)) {
          const alumnosData = responseAlumnos.data.data.map(alumno => ({
            matricula: alumno.id_alumno_clase,
            nombre: `${alumno.nombre_alumno} ${alumno.apellido_alumno}`,
          }));
          setAlumnos(alumnosData);
        } else {
          setAlumnos([]);
        }

        // 2. Obtener asignaciones (tareas) de la clase
        const urlAsign = obtenerAsignacionesPorClase(dataClase.matricula_clase);
        const responseAsign = await axios.get(urlAsign);
        const asignaciones = Array.isArray(responseAsign.data)
          ? responseAsign.data
          : (responseAsign.data.asignaciones || []);
        setTareas(asignaciones);

        // 3. Para cada asignación, obtener sus calificaciones de forma secuencial
        const results = await fetchCalificacionesSecuencial(asignaciones);
        const tempEntregas = {};
        results.forEach(result => {
            tempEntregas[result.id_asignacion] = result.deliveries;
        });
        setEntregasPorAsignacion(tempEntregas);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error al cargar los datos.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dataClase?.matricula_clase]);

  // Función para obtener la calificación de un alumno en una asignación.
  const getGradeForStudent = (alumnoMatricula, idAsignacion) => {
    const deliveries = Array.isArray(entregasPorAsignacion[idAsignacion])
      ? entregasPorAsignacion[idAsignacion]
      : [];
      const entrega = deliveries.find(item => String(item.id_alumno) === String(alumnoMatricula));
    return entrega ? entrega.calificacion : null;
  };

  // Función para calcular el promedio de calificaciones de un alumno.
  const computeStudentAverage = (alumnoMatricula) => {
    let sum = 0;
    let count = 0;
    tareas.forEach(asignacion => {
      const grade = getGradeForStudent(alumnoMatricula, asignacion.id_asignacion);
      if (grade !== null && grade !== undefined && grade !== '') {
        const num = parseFloat(grade);
        if (!isNaN(num)) {
          sum += num;
          count++;
        }
      }
    });
    if (count === 0) return '-';
    return (sum / count).toFixed(2);
  };

  if (loading) {
    return (
      <div className="contenedor-calificaciones">
        <h2>Calificaciones por Alumno 📝</h2>
        <p>Cargando datos...</p>
        {progress && <p>{progress}</p>}
      </div>
    );
  }

  if (error) {
    return <p className="mensaje-error">{error}</p>;
  }

  return (
    <div className="contenedor-calificaciones">
      <h2>Calificaciones por Alumno 📝</h2>
      <table className="tabla-calificaciones">
        <thead>
          <tr>
            <th>Alumnos</th>
            {tareas.map(asignacion => (
              <th key={asignacion.id_asignacion}>{asignacion.nombre}</th>
            ))}
            <th>Promedio</th>
          </tr>
        </thead>
        <tbody>
          {alumnos.map(alumno => (
            <tr key={alumno.matricula}>
              <td>{alumno.nombre}</td>
              {tareas.map(asignacion => {
                const grade = getGradeForStudent(alumno.matricula, asignacion.id_asignacion);
                return (
                  <td key={asignacion.id_asignacion}>
                    {grade !== null ? grade : 'No entregado'}
                  </td>
                );
              })}
              <td>{computeStudentAverage(alumno.matricula)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CalificacionTeacher;
