import React, { useState } from 'react';
import './PaginaInicio.css';
import './HomeworkPage.css';
import './TeacherHomework.css';  // 📌 Importamos los nuevos estilos
import Cabecera from '../Others/Cabecera';
import BotonBarraInferior from '../Others/BotonBarraInferior';
import BarraSuperior from '../Others/BarraSuperior';
import BarraInferior from '../Others/BarraInferior';
import { useNavigate } from 'react-router-dom';
import PiePagina from '../Others/PiePagina';
import { useData } from './DataContext';
import AssignHomework from './AsignarTareas';  
import TeacherGradeHomework from './TeacherGradeHomework';
import CaliciacionTeacher from './CaliciacionTeacher';
import BarraInferiorPersonalizadaTeacher from './BarraInferiorPersonalizadaTeacher';

const barra_inferior = <BarraInferior contenido={
    <BarraInferiorPersonalizadaTeacher/>
} />

const TeacherHomework = () => {
    const navigate = useNavigate();
    const { dataClase } = useData();
    const [vista, setVista] = useState('');

    const barra_superior = (
        <BarraSuperior 
            texto_cabecera={dataClase.nombre_clase} 
            redireccion={"teacher-home"} 
            profile_redireccion={"t-profile-page"}
        />
    );

    return (
        <div className='contenedor-pagina'>
            <Cabecera contenidosuperior={barra_superior} contenidoInferior={barra_inferior} />
            
            <div className="homework-container">
                <div className="h-title">
                    <h1>📚 Gestión de Tareas</h1>
                    <div className="button-group">
                        <button className={`boton-tarea ${vista === 'asignar' ? 'activo' : ''}`} onClick={() => setVista('asignar')}>
                            📌 Asignar Tarea
                        </button>
                        <button className={`boton-tarea ${vista === 'calificar' ? 'activo' : ''}`} onClick={() => setVista('calificar')}>
                            📊 Calificar Tareas
                        </button>
                        <button className={`boton-tarea ${vista === 'calificacion' ? 'activo' : ''}`} onClick={() => setVista('calificacion')}>
                        📝 Calificaciones
                        </button>
                    </div>
                </div>

                <div className="h-holder">
                    {vista === '' && <p>📌 Aquí puedes asignar tareas a los alumnos y gestionar sus calificaciones.</p>}
                    {vista === 'asignar' && <AssignHomework />}
                    {vista === 'calificar' && <TeacherGradeHomework />}
                    {vista === 'calificacion' && <CaliciacionTeacher />}
                </div>
            </div>

            <PiePagina imagenSrc={require('../Assets/piepagina.jpg')} />
            <footer>Grupo Derecho & Progreso &copy; 2024</footer>
        </div>
    );
}

export default TeacherHomework;
