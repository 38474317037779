import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { obtenerListadoMatriculasAlumnos, routeInsertEstudiante } from "./constants";

export const AssignClass = () => {
    const [matriculaAlumno, setMatriculaAlumno] = useState('');
    const [matriculaClase, setMatriculaClase] = useState('');
    const [idAlumno, setIdAlumno] = useState('');
    const [error, setError] = useState('');
    const [clases, setClases] = useState([]);
    const [alumnos, setAlumnos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(obtenerListadoMatriculasAlumnos);
                setClases(response.data.data.matriculas);
                setAlumnos(response.data.data.alumnos);
            } catch (error) {
                console.error("Error al obtener los datos", error);
            }
        };
        fetchData();
    }, []);

    const handleAlumnoSeleccionado = (e) => {
        const matriculaSeleccionada = e.target.value;
        setMatriculaAlumno(matriculaSeleccionada);

        const alumno = alumnos.find(a => `${a.matricula} - ${a.nombre} ${a.apellido}` === matriculaSeleccionada);
        if (alumno) {
            setIdAlumno(alumno.id);
        } else {
            setIdAlumno('');
        }
    };

    const handleSubmitInsertAlumnos = async (e) => {
        e.preventDefault();

        if (!idAlumno || !matriculaAlumno || !matriculaClase) {
            setError("Selecciona un alumno y una clase válidos.");
            return;
        }

        try {
            const response = await axios.post(routeInsertEstudiante, {
                id_alumno: idAlumno,
                matricula_alumno: matriculaAlumno.split(' - ')[0], // Extrae solo la matrícula
                matricula_clase: matriculaClase.split(' - ')[0]
            });

            console.log(response.data);
            alert("Alumno insertado correctamente");
        } catch (error) {
            console.error('Error al insertar alumno en la clase', error);
            setError('Error al insertar alumno en la clase, por favor intentelo de nuevo');
        }
    };

    return (
        <div className="full-page">
            <div className="container1">
                <form onSubmit={handleSubmitInsertAlumnos}>
                    <div className='title'>
                        <h1>Insertar Alumno a Clase</h1>
                    </div>
                    
                    {/* Selección de la Clase */}
                    <div className='class-id'>
                        <label>Matricula de la clase</label>
                        <input 
                            type="text" 
                            placeholder="Escribe para buscar..."
                            value={matriculaClase} 
                            onChange={(e) => setMatriculaClase(e.target.value)}
                            list="clases-list"
                        />
                        <datalist id="clases-list">
                            {clases.map((c) => (
                                <option key={c.id_clase} value={`${c.matricula_clase} - ${c.nombre_clase}`} />
                            ))}
                        </datalist>
                    </div>

                    {/* Selección del Alumno */}
                    <div className='student-id'>
                        <label>Matricula del estudiante</label>
                        <input 
                            type="text" 
                            placeholder="Escribe para buscar..."
                            value={matriculaAlumno} 
                            onChange={handleAlumnoSeleccionado}
                            list="alumnos-list"
                        />
                        <datalist id="alumnos-list">
                            {alumnos.map((a) => (
                                <option key={a.id} value={`${a.matricula} - ${a.nombre} ${a.apellido}`} />
                            ))}
                        </datalist>
                    </div>

                    <div className='submit'>
                        <button type="submit">Insertar Alumno</button>
                        {error && <div>{error}</div>}
                    </div>
                </form>
                <div className='return-home'>
                    <span onClick={() => navigate("/admin-home")}>Regresar Home</span>
                </div>
            </div>
        </div>
    );
};
