import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PaginaInicio.css";
import Cabecera from "../Others/Cabecera";
import PiePagina from "../Others/PiePagina";
import BotonBarraInferior from "../Others/BotonBarraInferior";
import BarraSuperior from "../Others/BarraSuperior";
import BarraInferior from "../Others/BarraInferior";
import { ContenidoTablon } from "../Others/ContenidoTablon";
import { useData } from "./DataContext";
import { getGrupoMatricula, deleteAlumnoClase, findByMatricula, routeFindUserByRol, updateClase, otorgarCertificado, obtenerIdTemplate } from "../Pages/constants";

export const AdminPaginaTablon = () => {
    const { dataClase } = useData();
    const [personas, setPersonas] = useState([]);
    
    const [idTemplate, setIdTemplate] = useState(null);
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [cursoData, setCursoData] = useState({
        nombre: "",
        matricula: "",
        profesor: "",
    });
    const [loading, setLoading] = useState(true);

    const [listaProfesores, setListaProfesores] = useState([]); // Inicialmente vacío
    const [loadingGrupo, setLoadingGrupo] = useState(false);
    const [sortDirection, setSortDirection] = useState("asc");
    useEffect(() => {
        if (dataClase?.matricula_clase) {
            fetchGrupoYTemplate();
        }
      }, [dataClase.matricula_clase]);
    
      const fetchGrupoYTemplate = async () => {
        try {
            setLoading(true);
    
            // 🔹 Obtener alumnos y certificados
            const grupoResponse = await axios.post(
                getGrupoMatricula,
                { matricula_clase: dataClase.matricula_clase },
                { headers: { "Content-Type": "application/json" } }
            );
    
            if (grupoResponse.status === 200 && grupoResponse.data.success) {
                setPersonas(grupoResponse.data.data || []);
                console.log("✅ Alumnos con certificado:", grupoResponse.data.data);
            } else {
                console.error("❌ Error en la respuesta de la API de alumnos:", grupoResponse.data);
            }
    
            // 🔹 Obtener `id_template`
            const templateResponse = await axios.get(obtenerIdTemplate(dataClase.matricula_clase));
    
            if (templateResponse.status === 200 && templateResponse.data.success) {
                setIdTemplate(templateResponse.data.id_template);
            } else {
                console.error("❌ No se encontró un template para esta clase.");
            }
    
        } catch (error) {
            console.error("❌ Error al obtener datos:", error);
        } finally {
            setLoading(false);
        }
    };
    
      // Ordenar la tabla por nombre (asc o desc):
  const handleSortByName = () => {
    const sorted = [...personas].sort((a, b) => {
      // Combinar nombre y apellido, y convertir a minúsculas
      const nameA = (a.nombre_alumno + " " + a.apellido_alumno).toLowerCase();
      const nameB = (b.nombre_alumno + " " + b.apellido_alumno).toLowerCase();

      if (nameA < nameB) return sortDirection === "asc" ? -1 : 1;
      if (nameA > nameB) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

    setPersonas(sorted);
    // Cambia la dirección de orden cada vez que clicas (asc <-> desc)
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

    const fetchProfesorDefault = async (matricula) => {
        try {
            console.log("📡 Obteniendo profesor con matrícula:", matricula);
            const response = await axios.get(`${findByMatricula}?matricula=${matricula}`);
            if (response.status === 200) {
                const profesor = response.data?.nombre + " " + response.data?.apellido + " - " + response.data?.matricula || "Profesor Desconocido";
                setCursoData((prev) => ({ ...prev, profesor }));
            } else {
                console.error("⚠️ Error obteniendo el profesor:", response.data);
            }
        } catch (error) {
            console.error("❌ Error en la solicitud del profesor:", error);
        }
    };

    const fetchProfesores = async () => {
        try {
            console.log("📡 Obteniendo lista de profesores...");
            const response = await axios.get(`${routeFindUserByRol}?role=Maestro`);
            
            if (response.status === 200 && response.data.success) {
                const profesores = response.data.data.map(profesor => ({
                    id: profesor.id,
                    nombre: profesor.nombre,
                    apellido: profesor.apellido,
                    matricula_maestro: profesor.matricula  // Guardar la matrícula real del profesor
                }));
                setListaProfesores(profesores);
                console.log("PRofesores", profesores);
            } else {
                console.error("⚠️ Error obteniendo la lista de profesores:", response.data);
            }
        } catch (error) {
            console.error("❌ Error en la solicitud de profesores:", error);
        }
    };
    

    const handleModifyCurso = async () => {
        setCursoData({
            nombre: dataClase?.nombre_clase || "Curso de Ejemplo",
            matricula: dataClase?.matricula_clase || "123456",
            profesor: "",
        });

        await fetchProfesorDefault(dataClase?.matricula_maestro);
        await fetchProfesores(); // Cargar la lista de profesores al abrir el modal
        setShowModal(true);
    };

    const handleSaveChanges = async () => {
        try {
            console.log("📡 DataClase:", dataClase);
            console.log("📡 CursoData:", cursoData);
    
            const payload = {
                id_clase: dataClase?.id_clase,  // Verifica que este ID esté presente
                nombre_clase: cursoData?.nombre,
                descripcion: cursoData?.nombre || "", // Asegurar que no sea undefined
                matricula_maestro: cursoData?.matricula_maestro,
                matricula_clase: dataClase?.matricula_clase,
                id_maestro: cursoData?.id_maestro || ""
            };
    
            console.log("📡 Enviando datos al backend:", payload); // Debug
    
            const response = await axios.put(updateClase, payload, { headers: { "Content-Type": "application/json" } });
    
            if (response.status === 200 && response.data.success) {
                alert("✅ Curso actualizado correctamente");
                setShowModal(false);
            } else {
                alert("⚠️ Error al actualizar el curso: " + response.data.message);
            }
        } catch (error) {
            console.error("❌ Error actualizando el curso:", error);
    
            // Captura y muestra detalles del error
            if (error.response) {
                console.error("⚠️ Respuesta del servidor:", error.response.data);
                console.error("⚠️ Código de estado:", error.response.status);
                console.error("⚠️ Headers:", error.response.headers);
                
                alert(`❌ Error ${error.response.status}: ${error.response.data.message || "Error desconocido en el servidor."}`);
            } else if (error.request) {
                console.error("⚠️ No hubo respuesta del servidor:", error.request);
                alert("❌ No hubo respuesta del servidor. Verifica tu conexión.");
            } else {
                console.error("⚠️ Error en la configuración de la solicitud:", error.message);
                alert(`❌ Error en la solicitud: ${error.message}`);
            }
        }
    };
    
    

    const handleDelete = async (id_alumno_clase) => {
        const confirmDelete = window.confirm("¿Seguro que quieres eliminar a este alumno?");
        if (confirmDelete) {
            try {
                const response = await axios.delete(deleteAlumnoClase, {
                    data: { id_alumno_clase: id_alumno_clase }
                });

                if (response.data.success) {
                    alert("Alumno eliminado correctamente");
                    setPersonas(personas.filter(persona => persona.id_alumno_clase !== id_alumno_clase));
                } else {
                    alert("No se encontró el alumno");
                }
            } catch (error) {
                console.error("Error eliminando alumno:", error);
                alert("Error eliminando alumno");
            }
        }
    };

 
    const handleCertificado = async (id_alumno, id_clase, id_certificado) => {
        if (id_certificado) {
            console.log(`Revocando certificado con ID ${id_certificado} para el alumno ${id_alumno}`);
            try {
                const response = await axios.delete("/api/certificados/revocar", {
                    data: { matricula_alumno: id_alumno, id_clase: id_clase }
                });

                if (response.status === 200) {
                    alert("✅ Certificado revocado correctamente");
                    fetchGrupoYTemplate(); // Recargar los datos sin refrescar la página
                }
            } catch (error) {
                console.error("❌ Error al revocar certificado:", error.response?.data || error);
                alert(`❌ No se pudo revocar el certificado: ${error.response?.data?.error || error.message}`);
            }
        } else {
            console.log(`Otorgando certificado al alumno ${id_alumno}`);
            try {
                const response = await axios.post(otorgarCertificado, {
                    matricula_alumno: id_alumno,
                    id_clase: id_clase,
                    id_template: idTemplate
                });

                if (response.status === 200) {
                    alert("✅ Certificado otorgado correctamente");
                    fetchGrupoYTemplate();
                }
            } catch (error) {
                console.error("❌ Error al otorgar certificado:", error.response?.data || error);
                alert(`❌ No se pudo otorgar el certificado: ${error.response?.data?.error || error.message}`);
            }
        }
    };

    const handleDeleteCurso = () => {
        const confirmDelete = window.confirm("⚠️ ¿Seguro que quieres eliminar este curso? Esta acción no se puede deshacer.");
        if (confirmDelete) {
            alert("Curso eliminado exitosamente (simulado).");
        }
    };
    const barra_inferior = (
        <BarraInferior contenido={
            <div className="barra-inferior-flex">
                <BotonBarraInferior imagenSrc={require("../Assets/tablon.png")} texto={"Foro de Discusión"} redireccion={"a-pagina-tablon"} />
                <BotonBarraInferior imagenSrc={require("../Assets/contenido.png")} texto={"Contenido"} redireccion={"a-pagina-content"} />
                <BotonBarraInferior imagenSrc={require("../Assets/certificado 2.png")} texto={"Certificados"} redireccion={"certificado-form"} />
                <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Ejercicios"} redireccion={"a-pagina-tareas"} />
                <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Diagnostica"} redireccion={"a-pagina-evaluacion"} />
                <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Final"} redireccion={"a-pagina-evaluacionfi"} />
                
                <div className="menu-container">
                    <button className="btn-menu" onClick={() => setShowMenu(!showMenu)}>
                        ⚙️ Administrar Curso
                    </button>
                    {showMenu && (
                        <div className="submenu">
                            <button onClick={handleDeleteCurso}>❌ Eliminar Curso</button>
                            <button onClick={handleModifyCurso}>✏️ Modificar Curso</button>
                        </div>
                    )}
                </div>
            </div>
        } />
    );
    return (
        <div className="contenedor-pagina">
            <Cabecera contenidosuperior={<BarraSuperior texto_cabecera={dataClase?.nombre_clase || "Cargando..."} redireccion={"admin-home"} profile_redireccion={"a-profile-page"} />} contenidoInferior={barra_inferior} />

            <ContenidoTablon imagen_materia={require("../Assets/materia.jpg")} redireccion={"admin-home"} />

           {/* 🔹 Pantalla de carga */}
           {loading ? (
                <div className="loading-screen">
                    <p>⏳ Cargando datos...</p>
                </div>
            ) : (
                <div className="listado-personas">
                    <h2>👥 Lista de Usuarios</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Rol</th>
                                <th>Certificado</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {personas.length > 0 ? (
                                personas.map((persona) => (
                                    <tr key={persona.id_alumno_clase}>
                                        <td>{persona.nombre_alumno + " " + persona.apellido_alumno}</td>
                                        <td>{persona.rol}</td>
                                        <td>
                                        <button
                        className={`btn-certificado ${persona.id_certificado ? "revocar" : "otorgar"}`}
                        onClick={() => handleCertificado(persona.id_alumno_clase, dataClase.matricula_clase, persona.id_certificado)}
                    >
                        {persona.id_certificado ? "Revocar" : "Dar Certificado"}
                    </button>
                                        </td>
                                        <td>
                                            <button className="btn-eliminar" onClick={() => console.log("Eliminar alumno", persona.id_alumno_clase)}>❌ Eliminar</button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: "center" }}>No hay personas registradas.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}

            <PiePagina imagenSrc={require("../Assets/lawbackground23.jpg")} />
            <footer>Grupo Derecho & Progreso &copy; 2024</footer>

            {/* 🔹 Modal para Editar Curso */}
            {showModal && (
                <div className="modal-overlay" onClick={() => setShowModal(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2>Editar Curso</h2>

                        <label>Nombre del Curso:</label>
                        <input type="text" value={cursoData.nombre} onChange={(e) => setCursoData({ ...cursoData, nombre: e.target.value })} />

                        <label>Matrícula de la Clase:</label>
                        <input type="text" value={cursoData.matricula} onChange={(e) => setCursoData({ ...cursoData, matricula: e.target.value })} />

                        <label>Nombre del Profesor:</label>
<select
    value={cursoData.matricula_maestro}  // Se mantiene la matrícula como valor inicial
    onChange={(e) => {
        const selectedMatricula = e.target.value;
        const selectedProfesor = listaProfesores.find(profesor => profesor.matricula_maestro === selectedMatricula);
        
        if (selectedProfesor) {
            setCursoData({
                ...cursoData,
                matricula_maestro: selectedProfesor.matricula_maestro,
                id_maestro: selectedProfesor.id // Ahora guardamos el id del profesor
            });
        }
    }}
>
    {listaProfesores.length > 0 ? (
        listaProfesores.map((profesor) => (
            <option key={profesor.id} value={profesor.matricula_maestro}>
                {profesor.nombre} {profesor.apellido} - {profesor.matricula_maestro}
            </option>
        ))
    ) : (
        <option value="">No hay profesores disponibles</option>
    )}
</select>


                        <div className="modal-buttons">
                            <button onClick={handleSaveChanges} className="btn-guardar">Guardar Cambios</button>
                            <button onClick={() => setShowModal(false)} className="btn-cancelar">Cancelar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
