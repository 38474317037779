import React, { useState } from 'react';
import './PaginaInicio.css';
import './HomeworkPage.css';
import Cabecera from '../Others/Cabecera';
import PiePagina from '../Others/PiePagina';
import BotonBarraInferior from '../Others/BotonBarraInferior';
import BarraSuperior from '../Others/BarraSuperior';
import BarraInferior from '../Others/BarraInferior';
import axios from 'axios';
import { useData } from './DataContext'; 
import { getLinkTeacher } from "./constants";
import './TeacherZoom.css';

const barra_inferior = <BarraInferior contenido={
    <>
        <BotonBarraInferior imagenSrc={require("../Assets/tablon.png")} texto={"Foro de Discusión"} redireccion={"t-pagina-tablon"} />
        <BotonBarraInferior imagenSrc={require("../Assets/contenido.png")} texto={"Contenido"} redireccion={"t-pagina-content"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Ejercicios"} redireccion={"t-pagina-tareas"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Diagnostica"} redireccion={"t-pagina-evaluacion"} />
        <BotonBarraInferior imagenSrc={require("../Assets/tareas.png")} texto={"Evaluación Final"} redireccion={"t-pagina-evaluacionfi"} />
        <BotonBarraInferior imagenSrc={require("../Assets/zoom.png")} texto={"Zoom"} redireccion={"t-pagina-zoom"} />
    </>
} />

const TeacherZoom = () => {
    const { data } = useData();
    const { dataClase } = useData();
    const [links, setLinks] = useState([]);
    const [showLinks, setShowLinks] = useState(false);

    const barra_superior = (
        <BarraSuperior 
            texto_cabecera={dataClase.nombre_clase} 
            redireccion={"teacher-home"} 
            profile_redireccion={"t-profile-page"} 
        />
    );

    const fetchLinks = async () => {
        if (data) {
            try {
                const response = await axios.get(getLinkTeacher, {
                    params: {
                        matricula_maestro: data.matricula 
                    }
                });
                setLinks(response.data.data);
                console.log(response.data.data);
                setShowLinks(true);
            } catch (error) {
                console.error('Error al obtener los enlaces:', error);
            }
        }
    };

    const handleFetchLinks = async () => {
        if (!showLinks) {
            await fetchLinks();
        } else {
            setLinks([]);
            setShowLinks(false);
        }
    };

    return (
        <div className='contenedor-pagina'>
            <Cabecera contenidosuperior={barra_superior} contenidoInferior={barra_inferior} />
            <div className="homework-container">
                <div className="h-title"><h1>Links de Clases</h1></div>
                <div className="h-holder">
                    <div className="h-content">
                        <div className="mostrar-usuarios">
                            <button onClick={handleFetchLinks}>
                                {showLinks ? 'Ocultar Links clases' : 'Ver Links clases'}
                            </button>
                        </div>
                        {showLinks && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nombre de la Clase</th>
                                        <th>Enlace Zoom</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {links.map((link, index) => (
                                        <tr key={index}>
                                            {/* Concatenamos matricula_clase y descripcion */}
                                            <td style={{ minWidth: "250px", maxWidth: "400px", whiteSpace: "normal", wordWrap: "break-word", textAlign: "left" }}>
{`${link.matricula_clase} - ${link.nombre_clase}`}</td>
                                            
                                            {/* Convertimos el enlace Zoom en un hipervínculo */}
                                            <td>
                                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                    {link.url}
                                                </a>
                                            </td>
                                            
                                            <td>{link.fecha}</td>
                                            <td>{link.hora}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            <PiePagina imagenSrc={require('../Assets/piepagina.jpg')} />
            <footer>Grupo Derecho & Progreso &copy; 2024</footer>
        </div>
    );
}

export default TeacherZoom;
