import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './RecoverPassword.css';
import {forgotPassword} from "./constants";

import emailIcon from '../Assets/username.png';
import background_img from '../Assets/background.jpg';
import logoBackground from '../Assets/logo.jpeg';

const apiRecuperar = "URL_DE_TU_API/recuperar-contraseña"; // Reemplázalo con la URL real de tu API


function RecoverPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(forgotPassword, { email });
            setMensaje(response.data.message);
            setError('');
        } catch (err) {
            setError(err.response?.data?.error || 'Error en la recuperación de contraseña');

            setMensaje('');
        }
    };

    return (
        <div className="fullpage">
            <div className='container'>
                <div className="background">
                    <img src={background_img} alt="" />
                </div>
                <div className="header">
                    <div className="logo">
                        <img src={logoBackground} alt="Logo" />
                    </div>
                </div>
                <div className="login-text">Recuperación de contraseña</div>
                <div className="inputs">
                    <div className="input">
                        <img src={emailIcon} alt="Email Icon" />
                        <input type="email" placeholder='Correo Electrónico' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                {mensaje && <div className="success-message">{mensaje}</div>}
                {error && <div className="error-message">{error}</div>}
                <div className="submit-container">
                    <button onClick={handleSubmit} disabled={!email.trim()}>Enviar enlace</button>
                </div>
                <div className="back-to-login">
                    <span onClick={() => navigate('/')} className="link">Volver al inicio de sesión</span>
                </div>
            </div>
        </div>
    );
}

export default RecoverPassword;
