import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './RecoverPassword.css';

import background_img from '../Assets/background.jpg';
import logoBackground from '../Assets/logo.jpeg';
import { showPassword } from './constants';


function ShowPassword() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("token");
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPassword = async () => {
            try {
                const response = await axios.post(showPassword, { token });
                setPassword(response.data.password);
            } catch (err) {
                setError(err.response?.data?.error || 'Error obteniendo la contraseña');
            } finally {
                setLoading(false);
            }
        };

        if (token) fetchPassword();
        else setError("Token inválido");
    }, [token]);

    return (
        <div className="fullpage">
            <div className='container'>
                <div className="background">
                    <img src={background_img} alt="" />
                </div>
                <div className="header">
                    <div className="logo">
                        <img src={logoBackground} alt="Logo" />
                    </div>
                </div>
                <div className="login-text">Recuperación de Contraseña</div>
                <div className="inputs">
                    {loading ? (
                        <p className="loading-message">Cargando...</p>
                    ) : error ? (
                        <p className="error-message">{error}</p>
                    ) : (
                        <p className="success-message">Tu contraseña es: <strong>{password}</strong></p>
                    )}
                </div>
                <div className="back-to-login">
                    <span onClick={() => navigate('/')} className="link">Volver al inicio de sesión</span>
                </div>
            </div>
        </div>
    );
}

export default ShowPassword;
