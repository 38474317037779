// constants.js
//export const apiUrl = 'http://18.116.132.3:3000';
//export const apiUrl = 'https://gdpcursa.grupoderechoyprogreso.com/abogadosprogreso';
//export const apiUrl = 'http://127.0.0.1:5000';
export const apiUrl = 'https://grupoderechoyprogreso.org';
//http://jmf.0b1.mytemp.website/abogadosprogreso

export const routeFindUserByRol = `${apiUrl}/api/users/findByRole`;
export const routeUpdateUser = `${apiUrl}/api/users/update`;
export const routeInsertEstudiante = `${apiUrl}/api/alumnos_clases/insertarAlumnoClase`;
export const routeCreateClase = `${apiUrl}/api/clases/createClaseMaestro`;
export const routeCreateUser = `${apiUrl}/api/users/create`;
export const routeCreateEvaluation = `${apiUrl}/api/evaluacion/updateEvaluacion`;
export const routeGetFinalEvaluation = `${apiUrl}/api/evaluacion/getEvaluacionFinal`;
export const routeGetEncuestaSatisfaccion = `${apiUrl}/api/evaluacion/getEncuestaSatisfaccion`;
export const routeGetDiagnosticEvaluation = `${apiUrl}/api/evaluacion/getEvaluacionDiagnostica`;
export const routeGetEvaluations = `${apiUrl}/api/evaluacion/getAllEvaluaciones`;
export const apiSubirExcel = `${apiUrl}/api/archivosXMLS/subirExcel`;
export const apiMostrarExcel = `${apiUrl}/api/archivosXMLS/mostrarExcel`;
export const apiDescargarExcel = `${apiUrl}/api/archivosXMLS/descargarExcel`;
export const apiEliminarExcel = `${apiUrl}/api/archivosXMLS/eliminarExcel`;
export const routeCreateLink = `${apiUrl}/api/zoom/generarLink`;
export const mostrarTareaApiAlumno = `${apiUrl}/api/archivos_pdf_alumnos/mostrarTareasAlumnos`;
export const descargarPDFApiAlumno = `${apiUrl}/api/archivos_pdf_alumnos/descargarPDFAlumno`;
export const updateCalificacionApiAlumno = `${apiUrl}/api/archivos_pdf_alumnos/updateCalificacion`;
export const postPDFApi = `${apiUrl}/api/archivos_pdf/subirPDF`;
export const getLinks = `${apiUrl}/api/zoom/getUltimoLinkZoom`;
export const postPDFApiAlumno = `${apiUrl}/api/archivos_pdf_alumnos/subirPDFAlumno`;
export const mostrarTareaApi = `${apiUrl}/api/archivos_pdf/mostrarTareas`;
export const descargarPDFApi = `${apiUrl}/api/archivos_pdf/descargarPDF`;
export const getUsuarios = `${apiUrl}/api/users/directory`;
export const apiLogin = `${apiUrl}/api/users/login`;
export const getAllLinks = `${apiUrl}/api/zoom/getAllLinks`;
export const getLinkTeacher = `${apiUrl}/api/zoom/getUltimoLinkZoomMaestro`;
export const getLinksDate = `${apiUrl}/api/zoom/getAllLinksByDate`;
export const setComment = `${apiUrl}/api/tablon_datos/insertar`;
export const getComment = `${apiUrl}/api/tablon_datos/todos`;
export const getClases = `${apiUrl}/api/clases/ClasesMaestroAdmin`;
export const getCommentMatricula = `${apiUrl}/api/tablon_datos/todosMatricula`;
export const postContent = `${apiUrl}/api/contenido_clases/subirContenido`;
export const downlodContent = `${apiUrl}/api/contenido_clases/descargarContenido`;
export const showContent = `${apiUrl}/api/contenido_clases/mostrarContenido`;
export const showAllContent = `${apiUrl}/api/contenido_clases/mostrarTodoElContenido`;
export const getClasesStudent = `${apiUrl}/api/alumno_clases/getClasesByMatricula`;
export const getClasesTeacherId = `${apiUrl}/api/clases/ClasesMaestroById`;
export const subirCertificado = `${apiUrl}/api/certificados/subirCertificado`;
export const descargarCertificado = `${apiUrl}/api/certificados/descargarCertificado`;
export const eliminarCertificado = `${apiUrl}/api/certificados/deleteCertificadosEntry`;
export const mostrarCertificado = `${apiUrl}/api/certificados/mostrarCertificado`;
export const routeGetGetAllCertificate = `${apiUrl}/api/carpetas/showAllCertificades`;
export const deleteContent = `${apiUrl}/api/contenido_clases/deleteContenidoClasesEntry`;
export const deleteUser = `${apiUrl}/api/users/deleteUsuario`;
export const deleteCourse = `${apiUrl}/api/clases/deleteClasesEntry`;
export const deleteFolder = `${apiUrl}/api/carpeta/eliminarCarpeta`;
export const selectFolder = `${apiUrl}/api/carpetas/selectCarpetas`;
export const selectAllFolders = `${apiUrl}/api/carpetas/selectAllCarpetas`;
export const createFolder = `${apiUrl}/api/carpetas/crearCarpeta`;
export const updateFolder = `${apiUrl}/api/carpeta/actualizarContenido`;
export const deleteFolderContent = `${apiUrl}/api/carpeta_documentos/deleteContenidoCarpeta`;
export const downloadFolderContent = `${apiUrl}/api/carpeta_documentos/descargarContenidoCarpeta`;
export const showFolderContent = `${apiUrl}/api/carpeta_documentos/mostrarContenidoCarpeta`;
export const uploadFolderContent = `${apiUrl}/api/carpeta_documentos/subirContenidoCarpeta`;
export const deleteZoomLink = `${apiUrl}/api/zoom/deleteZoomEntry`;
export const getGrupoMatricula = `${apiUrl}/api/clases/GrupoMatricula`;
export const deleteAlumnoClase = `${apiUrl}/api/clases/deleteAlumnoDeClase`;
export const findByMatricula = `${apiUrl}/api/users/findByMatricula`;
export const updateClase = `${apiUrl}/api/clases/update`;

export const subirArchivo = `${apiUrl}/api/nuevo_contenido_clases/subir`;
export const obtenerArchivosPorMatricula = (matricula) => `${apiUrl}/api/nuevo_contenido_clases/mostrar?matricula=${matricula}`;
export const descargarArchivo = (idArchivo) => `${apiUrl}/api/nuevo_contenido_clases/descargar?id_archivo=${idArchivo}`;

export const eliminarArchivo = `${apiUrl}/api/nuevo_contenido_clases/eliminar`;

export const crearAsignacion = `${apiUrl}/api/asignaciones/crear`;
export const obtenerAsignacionesPorClase = (matriculaClase) => 
  `${apiUrl}/api/asignaciones/listar?matricula_clase=${matriculaClase}`;

export const subirTarea = `${apiUrl}/api/tareas/subir`;
export const obtenerTareas = (idAsignacion) => 
  `${apiUrl}/api/tareas/listar?id_asignacion=${idAsignacion}`;

export const descargarTarea = (idArchivo) => 
  `${apiUrl}/api/tareas/descargar?id_archivo=${idArchivo}`;

export const calificarTarea = `${apiUrl}/api/tareas/calificar`;

export const subirEntrega = `${apiUrl}/api/entregas/subir`;
export const obtenerEntregasPorAlumno = (idAlumno) => `${apiUrl}/api/entregas/alumno?id_alumno=${idAlumno}`;
export const obtenerEntregasPorAsignacion = (idAsignacion) => `${apiUrl}/api/entregas/asignacion?id_asignacion=${idAsignacion}`;
export const obtenerTodasLasEntregas = `${apiUrl}/api/entregas/todas`;
export const calificarEntrega = `${apiUrl}/api/entregas/calificar`;
export const eliminarEntrega = `${apiUrl}/api/entregas/eliminar`;
export const getAlumnoByMatricula = `${apiUrl}/api/clases/getAlumnoByMatricula`;
export const descargarEntregasPorAlumno = `${apiUrl}/api/entregas/descargar-por-alumno`;
export const descargarUltimaEntregaPorAlumno = `${apiUrl}/api/entregas/descargar-ultima`;

export const calificacionesPorAsignacion = `${apiUrl}/api/entregas/calificaciones-por-asignacion`;
export const listaNoticias = `${apiUrl}/api/noticias`;
export const descargarImagenNoticia = `${apiUrl}/api/noticias/descargar-imagen/`;
export const subirNoticias = `${apiUrl}/api/noticias/subir`;
export const eliminarNoticia = `${apiUrl}/api/noticias/eliminar`;


export const forgotPassword = `${apiUrl}/api/users/forgot-password`;
export const resetPassword = `${apiUrl}/api/users/reset-password`;
export const showPassword = `${apiUrl}/api/users/show-password`;

export const obtenerPostsPorClase = (idClase) => `${apiUrl}/api/clases/${idClase}/posts`;
export const crearPost = `${apiUrl}/api/posts`;
export const crearRepost = (idPost) => `${apiUrl}/api/posts/${idPost}/repost`;
export const obtenerReposts = (idPost) => `${apiUrl}/api/posts/${idPost}/reposts`;

export const obtenerListadoMatriculasAlumnos = `${apiUrl}/api/clases/listadoMatriculasAlumnos`;


export const generarTemplateCertificado = `${apiUrl}/api/certificados_template/generar`;
export const otorgarCertificado = `${apiUrl}/api/certificados/otorgar`;

export const obtenerIdTemplate = (id_clase) => `${apiUrl}/api/certificados_template/${id_clase}/id_template`;

