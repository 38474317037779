import React, { useState, useEffect } from 'react';
import './ContenedorMaterias.css';
import axios from 'axios';
import { mostrarCertificado, descargarCertificado } from '../Pages/constants';
import { useData } from '../Pages/DataContext';
import { QRCodeCanvas } from "qrcode.react";

import { useNavigate } from 'react-router-dom';

const ContenedorConstanciasAlumnos = () => {
    const navigate = useNavigate();
    const [certificados, setCertificados] = useState([]);
    const { data } = useData();

    useEffect(() => {
        obtenerCertificados();
    }, []);

    const obtenerCertificados = async () => {
        console.log("Obteniendo certificados para la matrícula: " + data.matricula);
        try {
            const response = await axios.get(mostrarCertificado, {
                params: { matricula: data.matricula }
            });

            const dataTemp = response.data.map((item) => ({
                id_archivo: item.id_archivo,
                nombre_archivo: item.nombre_archivo,
                matricula_alumno: item.matricula_alumno,
                url: `${descargarCertificado}?id_archivo=${item.id_archivo}`
            }));

            setCertificados(dataTemp);
        } catch {
            alert("Error al obtener los certificados.");
        }
    };

    const handleDescargarCertificado = async (idArchivo) => {
        const url = `${descargarCertificado}?id_archivo=${idArchivo}`;
        window.open(url, "_blank");
    };

    return (
        <div className='container1'>
            <h2>📜 Mis Certificados</h2>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                    <tr>
                        <th style={{ padding: "10px", background: "#007bff", color: "white" }}>📖 Curso</th>
                        <th style={{ padding: "10px", background: "#007bff", color: "white", textAlign: "center" }}>🔗 Enlace</th>
                        <th style={{ padding: "10px", background: "#007bff", color: "white" }}>📱 QR</th>
                    </tr>
                </thead>
                <tbody>
                    {certificados.map((contenido, id) => (
                        <tr key={id} style={{ borderBottom: "1px solid #ddd" }}>
                            <td style={{ padding: "10px" }}>{contenido.nombre_archivo}</td>
                            <td style={{ padding: "10px", textAlign: "center" }}> {/* 📌 Centra el botón */}
                                <button 
                                    onClick={() => handleDescargarCertificado(contenido.id_archivo)}
                                    style={{ 
                                        padding: "8px 12px", 
                                        background: "#007bff", 
                                        color: "white", 
                                        border: "none", 
                                        borderRadius: "5px", 
                                        cursor: "pointer", 
                                        display: "inline-block" // 📌 Asegura que se mantenga centrado
                                    }}
                                >
                                    📥 Descargar
                                </button>
                            </td>
                            <td style={{ padding: "10px", textAlign: "center" }}>
                                <QRCodeCanvas value={contenido.url} size={80} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ContenedorConstanciasAlumnos;
