import React, { useEffect } from 'react';
import axios from 'axios';
import './PaginaInicio.css';
import Cabecera from '../Others/Cabecera';
import PiePagina from '../Others/PiePagina';
import BotonBarraInferior from '../Others/BotonBarraInferior';
import BarraSuperior from '../Others/BarraSuperior';
import BarraInferior from '../Others/BarraInferior';
import { ContenidoTablon } from '../Others/ContenidoTablon';
import { AnuncioTablon } from '../Others/AnuncioTablon';
import { useData } from './DataContext';
import { getAlumnoByMatricula } from './constants';
import './PaginaTablon.css';
import BarraInferiorPersonalizadaStudent from './BarraInferiorPersonalizadaStudent';

const barra_inferior = <BarraInferior contenido={
    <BarraInferiorPersonalizadaStudent/>
} />

export const PaginaTablon = () => {
    const { data, dataClase, dataAlumno, setDataAlumno } = useData(); // 🔥 Ahora tenemos acceso a setDataAlumno

    useEffect(() => {
        if (!data?.matricula || !dataClase?.matricula_clase) {
            console.warn("⚠️ Esperando que `data` y `dataClase` estén listos...");
            return;
        }

        const fetchAlumno = async () => {
            try {
                console.log("📌 Obteniendo datos del alumno...");
                console.log("Data: ", data);
                console.log("dataClase: ", dataClase);
                const response = await axios.post(getAlumnoByMatricula, {
                    matricula_alumno: data.matricula,
                    matricula_clase: dataClase.matricula_clase
                });

                if (response.data?.data) {
                    setDataAlumno(response.data.data); // 🔥 Guarda el alumno en el contexto global
                    console.log("✅ Alumno almacenado en el contexto:", response.data.data);
                } else {
                    console.warn("⚠️ No se encontró información del alumno.");
                }
            } catch (error) {
                console.error("⚠️ Error al obtener datos del alumno:", error);
            }
        };

        fetchAlumno();
    }, [data, dataClase]); // 🔥 Solo se ejecuta cuando `data` o `dataClase` cambian

    console.log("📌 dataClase:", dataClase);
    console.log("📌 dataAlumno:", dataAlumno); // 🔍 Verifica que se está obteniendo correctamente

    const barra_superior = <BarraSuperior 
        texto_cabecera={dataClase?.nombre_clase || "Cargando..."} 
        redireccion={"pagina-inicio"} 
        profile_redireccion={"profile-page"}
    />

    return (
        <div className='contenedor-pagina'>
            <Cabecera contenidosuperior={barra_superior} contenidoInferior={barra_inferior} />
            
          

            <ContenidoTablon 
                imagen_materia={require("../Assets/materia.jpg")} 
                anuncios_tablon={<AnuncioTablon 
                    nombre={'Instructor'} 
                    fecha={'5/9/2024'} 
                    titulo={'Foro: '} 
                    descripcion={' Expresa tus opiniones y comparte tus ideas con el resto del mundo manteniendo el contacto en este foro.'} 
                />}
                redireccion={"pagina-zoom"}
            />
            <PiePagina imagenSrc={require('../Assets/piepagina.jpg')} />
            <footer>Grupo Derecho & Progreso &copy; 2024</footer>
        </div>
    )
}
