import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { subirEntrega, obtenerAsignacionesPorClase, obtenerEntregasPorAlumno } from "./constants";
import { useData } from './DataContext';
import './HomeworkPage.css';
import Cabecera from '../Others/Cabecera';
import PiePagina from '../Others/PiePagina';
import BarraSuperior from '../Others/BarraSuperior';
import BarraInferior from '../Others/BarraInferior';
import BotonBarraInferior from '../Others/BotonBarraInferior';
import loader_img from '../Assets/spin.svg';
import BarraInferiorPersonalizadaStudent from './BarraInferiorPersonalizadaStudent';

const barra_inferior = (
    <BarraInferior contenido={
        <BarraInferiorPersonalizadaStudent/>
    } />
);

const HomeworkPage = () => {
    const { dataClase, dataAlumno } = useData();
    const [tareas, setTareas] = useState([]);
    const [calificaciones, setCalificaciones] = useState({});
    const [comentarios, setComentarios] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState('');
    const [loadingTareas, setLoadingTareas] = useState(true);
    const [loadingCalificaciones, setLoadingCalificaciones] = useState(true);

    const loading = loadingTareas || loadingCalificaciones;

    useEffect(() => {
        if (!dataClase?.matricula_clase) return;
        fetchTasks();
    }, [dataClase]);

    useEffect(() => {
        if (!dataAlumno?.id_alumno_clase) return;
        fetchCalificaciones();
    }, [dataAlumno]);

    const fetchTasks = async () => {
        try {
            console.log("📌 Obteniendo tareas...");
            const url = obtenerAsignacionesPorClase(dataClase.matricula_clase);
            const response = await axios.get(url);

            if (Array.isArray(response.data)) {
                setTareas(response.data);
                console.log("✅ Tareas cargadas:", response.data);
            } else {
                setTareas([]);
                console.warn("⚠️ No se encontraron tareas.");
            }
        } catch (error) {
            console.error("❌ Error al obtener las tareas:", error);
        } finally {
            setLoadingTareas(false);
        }
    };

    const fetchCalificaciones = async () => {
        try {
            console.log("📌 Obteniendo calificaciones y comentarios...");
            const response = await axios.get(obtenerEntregasPorAlumno(dataAlumno.id_alumno_clase));

            if (response.data.success && Array.isArray(response.data.data)) {
                const nuevasCalificaciones = {};
                const nuevosComentarios = {};
                response.data.data.forEach(entrega => {
                    nuevasCalificaciones[entrega.id_asignacion] = entrega.calificacion;
                    nuevosComentarios[entrega.id_asignacion] = entrega.comentarios || "Sin comentarios";
                });
                setCalificaciones(nuevasCalificaciones);
                setComentarios(nuevosComentarios);
            } else {
                setCalificaciones({});
                setComentarios({});
                console.warn("⚠️ La API no devolvió datos de calificaciones.");
            }
        } catch (error) {
            console.error("⚠️ Error al obtener calificaciones:", error);
        } finally {
            setLoadingCalificaciones(false);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmitPDF = async (event, tareaId) => {
        event.preventDefault();

        if (!tareaId || !selectedFile) {
            setMessage("❌ Error: Selecciona un archivo antes de subirlo.");
            return;
        }

        try {
            const formData = new FormData();
            formData.append('archivo', selectedFile);
            formData.append('id_asignacion', tareaId);
            formData.append('id_alumno', dataAlumno.id_alumno_clase);

            const response = await axios.post(subirEntrega, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.success) {
                setMessage('✅ Archivo subido exitosamente.');
                await fetchCalificaciones();
            } else {
                setMessage(`❌ Error al subir el archivo: ${response.data.error || 'Desconocido'}`);
            }
        } catch (error) {
            setMessage('❌ Error al subir la tarea.');
        }
    };

    return (
        <div className="contenedor-pagina">
            <Cabecera 
                contenidosuperior={<BarraSuperior texto_cabecera={dataClase?.nombre_clase || "Cargando..."} />}
                contenidoInferior={barra_inferior}
            />
            <div className="contenedor-calificaciones">
                <h2>{dataClase?.nombre_clase ? `${dataClase.nombre_clase} - Tareas` : "Cargando..."}</h2>

                {loading ? (
                    <img src={loader_img} className="center" alt="Cargando..." />
                ) : tareas.length === 0 ? (
                    <p className="mensaje-vacio">❌ No hay tareas disponibles.</p>
                ) : (
                    tareas.map((tarea) => (
                        <div key={tarea.id_asignacion} className="grupo-tarea">
                            <h3>{tarea?.nombre || "Tarea"}</h3>
                            <div className="tarea-subida">
                                <p>📅 Fecha de entrega: {tarea.fecha_limite}</p>
                            </div>

                            {calificaciones[tarea.id_asignacion] !== undefined ? (
                                <div>
                                    <p className="calificacion">📊 Calificación: {calificaciones[tarea.id_asignacion]}</p>
                                    <p className="comentarios">📝 Comentario: {comentarios[tarea.id_asignacion]}</p>
                                </div>
                            ) : (
                                <form onSubmit={(event) => handleSubmitPDF(event, tarea.id_asignacion)} className="form-subida">
                                    <input type="file" accept=".pdf" onChange={handleFileChange} />
                                    <button className="boton-subir" type="submit">📤 Subir Tarea</button>
                                </form>
                            )}
                        </div>
                    ))
                )}
            </div>
            <PiePagina imagenSrc={require('../Assets/piepagina.jpg')} />
        </div>
    );
};

export default HomeworkPage;
