import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PaginaInicio.css';
import './HomeworkPage.css';
import './TeacherContent.css'; 
import Cabecera from '../Others/Cabecera';
import PiePagina from '../Others/PiePagina';
import {subirArchivo, obtenerArchivosPorMatricula, descargarArchivo, eliminarArchivo, downlodContent, showContent, postContent, deleteContent } from "./constants";
import { useData } from './DataContext';
import BotonBarraInferior from '../Others/BotonBarraInferior';
import BarraSuperior from '../Others/BarraSuperior';
import BarraInferior from '../Others/BarraInferior';
import BarraInferiorPersonalizadaTeacher from './BarraInferiorPersonalizadaTeacher';

const barra_inferior = <BarraInferior contenido={
    <BarraInferiorPersonalizadaTeacher/>
} />;

export const TeacherContent = () => {
    const { dataClase } = useData();
    const [archivo, setArchivo] = useState(null);
    const [tipoArchivo, setTipoArchivo] = useState('pdf');
    const [mensaje, setMensaje] = useState('');
    const [archivos, setArchivos] = useState([]);
    const [mensaje2, setMensaje2] = useState('');

    useEffect(() => {
        obtenerContenido();
    }, []);

    const obtenerContenido = async () => {
        try {
            const response = await axios.get(obtenerArchivosPorMatricula(dataClase.matricula_clase));
            setArchivos(response.data);
        } catch (error) {
            console.error('Error al obtener el contenido:', error);
            setMensaje2('Error al obtener el contenido.');
        }
    };

    const handleArchivoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setArchivo(file);
    
            // 📌 Obtener la extensión del archivo automáticamente
            const extension = file.name.split('.').pop().toLowerCase();
            setTipoArchivo(extension);
        }
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!archivo) {
            setMensaje('Por favor seleccione un archivo.');
            return;
        }
    
        const formData = new FormData();
        formData.append('archivo', archivo);
        formData.append('id_clase', dataClase.id_clase);
        formData.append('matricula_clase', dataClase.matricula_clase);
        formData.append('tipo_archivo', tipoArchivo); // 📌 Se usa la extensión detectada automáticamente
    
        try {   
            const response = await axios.post(subirArchivo, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
    
            setMensaje(response.data.message);
            setArchivo(null);
            obtenerContenido();
        } catch (error) {
            setMensaje('Error al subir el archivo.');
            console.error('Error al subir el archivo:', error);
        }
    };
    
    

    const descargarContenido = async (id_archivo, nombreArchivo) => {
        try {
            const response = await axios.get(descargarArchivo(id_archivo), {
                responseType: 'blob',
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error al descargar contenido:', error);
        }
    };
    

    // 🔹 Función para ELIMINAR archivos
    const deleteContenidoClasesEntry = async (id_archivo) => {
        try {
            const response = await axios.delete(eliminarArchivo, {
                data: { id_archivo },
                headers: { 'Content-Type': 'application/json' }
            });
    
            if (response.status === 200) {
                alert("Archivo eliminado correctamente");
                setArchivos(archivos.filter(item => item.id_archivo !== id_archivo));
            } else {
                alert('Error al eliminar el archivo');
            }
        } catch (error) {
            console.error('Error al eliminar el archivo:', error);
        }
    };
    const barra_superior = (
        <BarraSuperior
            texto_cabecera={dataClase.nombre_clase}
            redireccion={'teacher-home'}
            profile_redireccion={'t-profile-page'}
        />
    );
    return (
        <div className="contenedor-pagina">
            <Cabecera contenidosuperior={barra_superior} contenidoInferior={barra_inferior} />
            <div className="homework-container">
                <div className="h-holder">
                    <h2>📁 Subir Contenido</h2>

                    {/* Nuevo diseño de formulario */}
                    <form className="formulario-subida" onSubmit={handleSubmit}>
                    <div className="campo">
    <label htmlFor="archivo">Seleccionar archivo:</label>
    <input type="file" id="archivo" onChange={handleArchivoChange} />
</div>

{archivo && (
    <div className="archivo-preview">
        <p>📄 <strong>Archivo:</strong> {archivo.name}</p>
        <p>📌 <strong>Tipo:</strong> {tipoArchivo.toUpperCase()}</p>
    </div>
)}

<button className="boton-subir" type="submit">⬆ Subir Archivo</button>

                    </form>

                    {mensaje && <p className="mensaje">{mensaje}</p>}

                    {/* Tabla de archivos */}
                    <h3>📂 Archivos Subidos</h3>
                    <table className="tabla-archivos">
    <thead>
        <tr>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Descargar</th>
            <th>Eliminar</th>
        </tr>
    </thead>
    <tbody>
        {archivos.map((archivo, index) => (
            <tr key={index}>
                <td>{archivo.nombre_archivo}</td>
                <td>{archivo.tipo_archivo}</td>
                <td>
                    <button className="boton-descargar" onClick={() => descargarContenido(archivo.id_archivo, archivo.nombre_archivo)}>⬇ Descargar</button>
                </td>
                <td>
                    <button className="boton-eliminar" onClick={() => deleteContenidoClasesEntry(archivo.id_archivo)}>🗑 Eliminar</button>
                </td>
            </tr>
        ))}
    </tbody>
</table>


                    {mensaje2 && <p className="mensaje">{mensaje2}</p>}
                </div>
            </div>

            <PiePagina />
        </div>
    );
};
