import React, { useState } from "react";
import "./CertificadoForm.css";
import CertificadoPDF from "./CertificadoPDF";
import Cabecera from "../Others/Cabecera";
import PiePagina from "../Others/PiePagina";
import BarraSuperior from "../Others/BarraSuperior";
import BarraInferior from "../Others/BarraInferior";
import { generarTemplateCertificado } from "./constants";
import { useData } from "./DataContext";

const CertificadoForm = () => {
    const [formData, setFormData] = useState({
        nombres: "",
        paterno: "",
        materno: "",
        capacitacion: "",
        fecha: "",
        horas: "",
        expedicion: ""
    });

    const [mostrarBoton, setMostrarBoton] = useState(false);
    const { dataClase } = useData();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMostrarBoton(true);
        console.log(dataClase);
        try {
            const response = await fetch(generarTemplateCertificado, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id_clase: dataClase.matricula_clase, // Puedes obtenerlo dinámicamente
                    nombre: formData.nombres,
                    apellido_paterno: formData.paterno,
                    apellido_materno: formData.materno,
                    capacitacion: formData.capacitacion,
                    fecha: formData.fecha,
                    horas: formData.horas,
                    fecha_expedicion: formData.expedicion
                }),
            });
    
            const result = await response.json();
            alert(result.message);
        } catch (error) {
            console.error("Error al generar certificado:", error);
            alert("Hubo un error al generar el certificado.");
        }
    };

    return (
        <div className="contenedor-pagina">
            <Cabecera contenidosuperior={<BarraSuperior texto_cabecera="Generar Certificado" redireccion={"admin-home"} profile_redireccion={"a-profile-page"} />} contenidoInferior={<BarraInferior />} />

            <div className="certificado-container">
                <h1>🎓 Generar Certificado</h1>
                <form onSubmit={handleSubmit} className="certificado-form">
                    <label>👤 Nombres:</label>
                    <input type="text" name="nombres" value={formData.nombres} onChange={handleChange} placeholder="Ejemplo: Juan Carlos" required />

                    <label>🔹 Apellido Paterno:</label>
                    <input type="text" name="paterno" value={formData.paterno} onChange={handleChange} placeholder="Ejemplo: Pérez" required />

                    <label>🔹 Apellido Materno:</label>
                    <input type="text" name="materno" value={formData.materno} onChange={handleChange} placeholder="Ejemplo: Gómez" required />

                    <label>📌 Capacitación:</label>
                    <input type="text" name="capacitacion" value={formData.capacitacion} onChange={handleChange} placeholder="Ejemplo: Desarrollo Web con React" required />

                    <label>📅 Fecha:</label>
                    <input type="text" name="fecha" value={formData.fecha} onChange={handleChange} placeholder="Ejemplo: 1 al 5 de Marzo" required />

                    <label>⏳ Horas:</label>
                    <input type="text" name="horas" value={formData.horas} onChange={handleChange} placeholder="Ejemplo: 20 horas" required />

                    <label>📆 Fecha de Expedición:</label>
                    <input type="text" name="expedicion" value={formData.expedicion} onChange={handleChange} placeholder="Ejemplo: 10 de Marzo de 2025" required />

                    <button type="submit" className="btn-guardar">Generar Certificado</button>
                </form>

                {mostrarBoton && <CertificadoPDF {...formData} />}
            </div>

            <PiePagina imagenSrc={require("../Assets/lawbackground23.jpg")} />
        </div>
    );
};

export default CertificadoForm;
